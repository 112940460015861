import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ArtistContext } from '../contexts/Context';
import { getLabelText, handleICalIconClick } from '../../functions/functions';
import { token } from '../../App';
import { NavLink } from 'reactstrap';
import ArtistList from '../misc/Artistlist';
import ICalModal from '../misc/ICalModal';
import { useJoyride } from "../misc/Joyride";
import logo from '../icons/USlogo.png';
import { arrowRightIcon, tourIcon, settingsIcon, dayschemeIcon, shareIcon, questionIcon, micIcon } from '../icons/icons'

// startsidan med navigering till de andra sidorna
export function Home() {
  
  const [selectedArtist] = useContext(ArtistContext);
  const [isICalModalOpen, setIsICalModalOpen] = useState(false);
  const [steps] = useState([ //här sätts stegen som visas i guiden
    {
      target: '.home-title',
      content: getLabelText('app_hint_label'),
      disableBeacon: true,
      styles: {
        floaterArrow: {
          display: 'none',
        },
      },
    },
    {
      target: '.artist-select-home',
      content: getLabelText('app_hint_artist'), //'Här väljer du artist/projekt'
    },
    {
      target: '#home-events',
      content: getLabelText('app_hint_commingevents'), //'Klicka här för att se kommande event'
    },
    {
      target: '#home-calendar',
      content: getLabelText('app_hint_calendar'), //'Klicka här för att komma till kalendern'
    },
    {
      target: '#home-ical',
      content: getLabelText('app_hint_ical'), //'Här kan du spara events till din iCal kalender'
    },
    {
      target: '#home-tour',
      content: getLabelText('app_hint_tour'), //'Klicka här för att se info om den aktuella turnéen'
    },
    {
      target: '#home-settings',
      content: getLabelText('app_hint_settings'), //'Här kan du ändra dina inställningar'
    },
  ]);
  const [joyride, startTour, stopTour] = useJoyride(steps);

  // skickar användaren till login-sidan om token är null
  useEffect(() => {
    if (token === null) {
      window.location.href = ('https://app.ustage.se/login');
    };
  }, []);

  const artistId = localStorage.getItem("selectedArtistId");

  return (
    <>
      <div className='home'>
        <div className='home-title'>
          <img src={logo} alt="logo" />
        </div>
        <div className="artist-select-home">
          <ArtistList />
        </div>
        <NavLink tag={Link} to={`/events/${artistId}`} >
          <li className="home-menu" id="home-events"><h2>{micIcon} {getLabelText('app_menu_UpcomingEvents')} </h2>{arrowRightIcon}</li>
        </NavLink>

        <NavLink tag={Link} to={`/calendar/${artistId}`}>
          <li className="home-menu" id="home-calendar"><h2>{dayschemeIcon} {getLabelText('app_menu_Calendar')} </h2>{arrowRightIcon}</li>
        </NavLink>

        <li className="home-menu" id="home-ical" onClick={() => handleICalIconClick(isICalModalOpen, setIsICalModalOpen)}><h2> {shareIcon} {getLabelText('app_menu_IcalLinks')} </h2>
          <ICalModal isICalModalOpen={isICalModalOpen} /></li>

        <NavLink tag={Link} to={`/tour/${artistId}`}>
          <li className="home-menu" id="home-tour"><h2>{tourIcon} {getLabelText('app_menu_Tourinfo')} </h2>{arrowRightIcon}</li>
        </NavLink>

        <NavLink tag={Link} to="/settings">
          <li className="home-menu" id="home-settings"><h2>{settingsIcon} {getLabelText('app_menu_Settings')} </h2>{arrowRightIcon}</li>
        </NavLink>
        <div className="old-link">
          <button onClick={startTour}>{questionIcon}</button>
          <a href='https://ustage.se'>
            <p >
              {getLabelText('app_home_OldUstage')}
            </p>
          </a>
          {joyride}
        </div>
      </div>
    </>
  )
}