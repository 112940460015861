import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { ArtistContext } from '../contexts/Context';
import { checkToken, getLabelText } from '../../functions/functions';
import { apiUrl, token } from "../../App";
import { PageHeader } from "../navs/Headers";

// komponent som visar info om den valda artistens turné
export function TourInfo() {
  const history = useHistory();
  const [selectedArtist, setSelectedArtist] = useContext(ArtistContext);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const [tourInfo, setTourInfo] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [filter, setFilter] = useState('present');
  const [loading, setLoading] = useState(true);
  const artistId = localStorage.getItem("selectedArtistId");

  useEffect(() => {
    checkToken();
  }, []);

  // funktion som hämtar tour info och projekt info
  async function fetchTourData() {
    try {
      const response = await fetch(`${apiUrl}/Object?id=${artistId}&token=${token}`);
      const data = await response.json();
      console.log(data)
      setTourInfo(data);

      const startDateField = data.Fields.find(field => field.FieldName === 't_TourStart');
      const endDateField = data.Fields.find(field => field.FieldName === 't_TourEnd');

      const tourStartDate = startDateField ? startDateField.FieldValue : null;
      const tourEndDate = endDateField ? endDateField.FieldValue : null;

      const response2 = await fetch(`${apiUrl}/UserProjects?token=${token}&id=${artistId}&type=&start=${tourStartDate}&end=${tourEndDate}&all=false`);
      const data2 = await response2.json();
      console.log(data2)
      setProjectData(data2);


      if (selectedArtist) {
        window.history.pushState({}, "", "tour" + `/${selectedArtist.id}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchTourData();
  }, [artistId]);

  const tourInfoItems = tourInfo.Fields

  // komponent som visar turnéinfon
  const TourInfo = () => {

    if (loading) {
      return (
        <div className="tourinfo">
          <h2>{getLabelText(`app_tour_loading`)}</h2>
        </div>
      )
    } else {
      return (
        <div className="tourinfo">
          <div className="tourinfo-title">
          <h2>{tourInfo.DisplayName}</h2>
          </div>
          {tourInfoItems
            ?.filter(item => item.FieldValue && item.FieldValue.trim() !== '' && !item.FieldName.includes('dName'))
            .map((item, index) => (
              <div className="tourinfo-item" key={index}>
                <h4>{getLabelText(`${item.FieldName}`)}</h4>
                {item.FieldName === 't_SalesInfo' || item.FieldName === 't_TourInfo' ? (
                  <span dangerouslySetInnerHTML={{ __html: item.FieldValue }} />
                ) : (
                  <p>{item.FieldValue}</p>
                )}
              </div>
            ))}
        </div>
      )
    }
  }

  // komponent som visar projektinfon
  const ProjectInfo = () => {
    const today = new Date();

    const groupedProjects = projectData.reduce((grouped, project) => {
      const startDate = new Date(project.Start);
      const stopDate = new Date(project.Stop);

      if (
        (filter === 'past' && stopDate < today) ||
        (filter === 'present' && startDate <= today && today <= stopDate) ||
        (filter === 'upcoming' && today < startDate)
      ) {
        const key = project.ProjectName;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(project);
      }

      return grouped;
    }, {});

    if (projectData.length > 0) {
      return (
        <>
          <div className="tourinfo">
            <div className="tourinfo-title">
              <h2>{getLabelText('app_tour_YourProjects')}</h2>
              <select value={filter} onChange={e => setFilter(e.target.value)}>
                <option value="past">{getLabelText('app_tour_Past')}</option>
                <option value="present">{getLabelText('app_tour_Current')}</option>
                <option value="upcoming">{getLabelText('app_tour_Upcoming')}</option>
              </select>
            </div>
            {Object.entries(groupedProjects).map(([projectName, projects], index) => (
              <div className="projectinfo" key={index}>
                <h3>{projectName}</h3>
                {projects.map((project, i) => (
                  <div key={i}>
                    <div className="project-person">
                      <h4 onClick={() => {
                        if (selectedPersonId === project.PersonID) {
                          setSelectedPersonId(null);
                          localStorage.setItem('selectedPerson', null);
                        } else {
                          setSelectedPersonId(project.PersonID);
                          localStorage.setItem('selectedPerson', project.PersonID);
                          history.push(`/person/${project.PersonID}`);
                        }
                      }}>
                        {project.FirstName} {project.LastName}
                      </h4>
                      <p>{project.Title}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      );
    } else return (
      <div className="tourinfo">
        <p>{getLabelText('app_tour_NoProjects')}</p>
      </div>
    )
  };

  return (
    <>
      <PageHeader />
      <div className="Tour">
        <TourInfo />
        <ProjectInfo />
      </div>
    </>
  );
}