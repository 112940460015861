import React, { useState } from 'react';

// context för att spara vald artist och valt event

export const ArtistContext = React.createContext();
export const EventContext = React.createContext();

export const ArtistContextProvider = ({ children }) => {
  const [selectedArtist, setSelectedArtist] = useState(null);

  return (
    <ArtistContext.Provider value={[selectedArtist, setSelectedArtist]}>
      {children}
    </ArtistContext.Provider>
  );
};

export const EventContextProvider = ({ children }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <EventContext.Provider value={[selectedEvent, setSelectedEvent]}>
      {children}
    </EventContext.Provider>
  );
};
