import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { ArtistContext, EventContext } from '../contexts/Context';
import { checkToken, formatHeaderDate, handleICalIconClick, handleLogout, setCalendarLanguage, useWindowResize } from '../../functions/functions';
import { apiUrl, token, desktopBreakpoint } from '../../App';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'
import { format } from 'date-fns'
import { PageHeader } from "../navs/Headers";
import { EventInfo } from '../eventinfo/EventInfo';
import { EventEdit } from '../eventinfo/EventEdit';
import ICalModal from '../misc/ICalModal';
import { plusIcon, shareIcon, spinnerIcon } from '../icons/icons';

// sida som visar kalender med events
export function Calendar() {
    const history = useHistory()
    const [selectedArtist, setSelectedArtist] = useContext(ArtistContext);
    const [selectedEvent, setSelectedEvent] = useContext(EventContext);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isICalModalOpen, setIsICalModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [language, setLanguage] = useState(false);
    const [editRight, setEditRight] = useState(null);
   
    
    const windowWidth = useWindowResize();
    const artistId = localStorage.getItem("selectedArtistId");

    // useEffect(() => {
    //     checkToken();
    // }, []);

    useEffect(() => {
        setCalendarLanguage(setLanguage)
    }, []);

    // funktion för att hämta events
    async function fetchCalendarData() {
        try {
            const response = await fetch(`${apiUrl}/Calendar?token=${token}&id=${artistId}`);
            const statusCode = response.status

            if (statusCode === 403) {
                handleLogout()
            }

            const jsonData = await response.json();
            setEvents(jsonData.CalenderEvents);
           

            if (selectedArtist) {
                window.history.pushState({}, "", "calendar" + `/${selectedArtist.id}`);
            }

            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch calendar', error);
        }
    };

    // när sidan öppnas väljs valt event bort och data hämtas
    useEffect(() => {
        if(selectedDate == null){

            setSelectedEvent(null)
            fetchCalendarData()
        }
    }, [artistId, selectedDate]);

    // sätter rätt värden i schemat
    const fetchedEvents = events.map(event => ({
       
        title: event.Status === 't_TBC' ? "(tbc) "+ (event.Place ? event.Place.charAt(0) + event.Place.slice(1).toLowerCase() : event.Name): (event.Place ? event.Place.charAt(0) + event.Place.slice(1).toLowerCase() : event.Name),
        start: event.Start,
        end: (event.End.slice(11) =="00:00:00"?event.End.slice(0,-8)+"23:00:00": event.End),
        id: event.ID,
        status: event.Status,
        type: event.StatusType,
        allDay: false,
    }));

    // funktion som visar vald komponent
    function renderSelectedComponent() {
        if (windowWidth >= desktopBreakpoint) {
            if (selectedEvent !== null) {
                return <EventInfo eventId={selectedEvent.ID} />;
            } else if (selectedDate !== null && editRight >= 2) {
                return <EventEdit  selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            }
        }
        return null;
    };

    // funktion för när man klickar på ett event
    const handleEventClick = useCallback((info) => {
        const clickedEvent = events.find(event => event.ID === info.event.id);

        setSelectedEvent(prevEvent => {
            if (prevEvent && prevEvent.ID === clickedEvent.ID) {
                window.history.pushState({}, '', 'calendar' + `/${selectedArtist.id}`);
                return null;
            } else {
                window.history.pushState({}, '', 'event' + `/${clickedEvent.ID}`);
                if (windowWidth < desktopBreakpoint) {
                    history.push(`/event/${clickedEvent.ID}`);
                }
                console.log(clickedEvent)
                return clickedEvent;
            }
        });

        setEvents([...events]);

    }, [windowWidth, history, setSelectedEvent, selectedArtist, events]);


    // Laddningsindikator
    const LoadingSpinner = () => {
        return (
            <div className="spinner">
                {spinnerIcon}
            </div>
        )
    }

    // eventinfon som visas i headern
    const HeaderTitle = () => {
        if (selectedEvent && windowWidth >= desktopBreakpoint) {
            return (
                <div className="header-title2">
                    {selectedEvent &&
                        <>
                            <h3>{selectedEvent.Name}</h3>
                            <h3>{formatHeaderDate(selectedEvent.Start)}</h3>
                        </>
                    }
                </div>
            )
        } else return null
    }

    // funktion för när man klickar på ett tomt event
    function handleNewEventClick(date) {
        setSelectedEvent(null)

        let newSelectedDate;

        if (date) {
            newSelectedDate = date.dateStr
        } else newSelectedDate = format(new Date(), 'yyyy-MM-dd')

       
        setSelectedDate(newSelectedDate)

        // kollar rättigheter för att redigera
        async function checkRights() {
            try {
                const artistId = localStorage.getItem("selectedArtistId");
                const response = await fetch(`${apiUrl}/UserProductionRights?id=${artistId}&date=${newSelectedDate}&token=${token}`);
                const jsonData = await response.json();
                
                setEditRight(jsonData)
            } catch (error) {
                console.error('Failed to fetch edit rights', error);
            }
        };

        checkRights()

        if (selectedDate === date.dateStr) {
            setSelectedDate(null)
        }

        if (windowWidth < desktopBreakpoint && editRight >= 2) {
            history.push(`/event-edit`);
        }
    }

    // funktion för när man ska lägga till nytt event
    const handleAddNewEvent = () => {
        setSelectedEvent(null)
        setSelectedDate(format(new Date(), 'yyyy-MM-dd'))
        if (windowWidth < desktopBreakpoint && editRight >= 2) {
            history.push(`/event-edit`);
        }
    }

    const PlusIcon = () => {
        if (editRight >= 2) {
            return (
                <button className="plus-button" onClick={() => handleAddNewEvent()}> {plusIcon}</button>
            )
        } else return null
    }

    return (
        <>
            <PageHeader />
            <HeaderTitle />
            <div className="Calendar">
                {loading ? (
                    <>
                        <LoadingSpinner />
                    </>
                ) : (
                    <>
                        <FullCalendar
                        nextDayThreshold={'00:00:00'}
                            plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'title',
                                right: 'prev multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay next'
                            }}
                            initialView="multiMonthYear"
                            displayEventTime={false}
                            events={fetchedEvents}
                            showNonCurrentDates={false}
                            editable={true}
                            eventStartEditable={true}
                            eventResizableFromStart={true}
                            fixedWeekCount={false}
                            locale={language}
                            dateClick={function (date) {
                                handleNewEventClick(date)
                            }}
                            eventClick={handleEventClick}
                            eventClassNames={(eventInfo) => {
                                if (selectedEvent && selectedEvent.ID === eventInfo.event.id) {
                                    return ["selected-event"];
                                } else if (eventInfo.event.extendedProps.type === "2") {
                                    return ["locked-event"];
                                } else if (eventInfo.event.extendedProps.type === "3") {
                                    return ["other-event"];
                                } else if (eventInfo.event.extendedProps.status === "t_TBC") {
                                    return ["tbc-event"];
                                }
                            }}
                        />
                        <div className="ical">
                            {/* <PlusIcon /> */}
                            <button title="iCal-länkar" className="ical-button" onClick={() => handleICalIconClick(isICalModalOpen, setIsICalModalOpen)}> {shareIcon}</button>
                            <ICalModal isICalModalOpen={isICalModalOpen} />
                        </div>
                        <div className='selected-component'>
                            {renderSelectedComponent()}
                        </div>
                    </>
                )}

            </div>
        </>
    )
}