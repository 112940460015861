import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiUrl } from '../../App';

// login-sida 
export function Login() {
    const history = useHistory()

    // funktion för att sätta token från URL
    function getUrlVars() {
        let vars = [], hash;
        const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }

        return vars;
    }

    const token = decodeURIComponent(getUrlVars()['token']).split(";")[0].replace('"',"")


    // funktion för att spara token i localStorage


     useEffect(() => {
        function handleLogin() {
        
            if (token.length === 48) {
                const data = token.substring(2, token.length - 3).split(";")[0].slice(1);
                window.localStorage.setItem("token", data);
            } else if (token.length === 36) {
                const data = token;
                window.localStorage.setItem("token", data);
            }
    
            if (getUrlVars()["refpage"] != undefined) {
                

                history.push(getUrlVars()["refpage"])
            }
    
            else{
               
                history.push('/')
                window.location.href = "/"
                
            } 
        }
    
        // funktion för att hämta user ID
        async function fetchUserData() {
            try {
                const response = await fetch(`${apiUrl}/User?token=${token}`);
                const userData = await response.json();
                localStorage.setItem('userId', userData.PersonID);
                fetchObjectUserData(userData.PersonID)
                
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }
        }
    
        // funktion för att hämta användarens namn
        async function fetchObjectUserData(id) {
            try {
            
                const response = await fetch(`${apiUrl}/Object?id=${id}&token=${token}`);
                const objectUserData = await response.json();
                localStorage.setItem('username', objectUserData.DisplayName);
                fetchSettings()
                
            } catch (error) {
                console.error('Failed to fetch object user data', error);
            }
        }
    
        // funktion för att hämta ordlistan
         async function fetchDictionary() {
            try {
                const response = await fetch(`${apiUrl}/dictionaries?token=${token}`);
                const jsonData = await response.json();
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                if (response.statusText === "OK") {
                    localStorage.setItem("dictionary", JSON.stringify(jsonData))
                    console.log("dic set")
                  
                    localStorage.setItem("login", "done")
                    handleLogin()
                }
            } catch (error) {
                console.error('Failed to fetch dictionary', error);
                
            }
    
           
        }
    
        // funktion för att inställningar och sätta valt språk
        async function fetchSettings() {
            try {
                const response = await fetch(`${apiUrl}/Settings?token=${token}`);
                const settingsData = await response.json();
    
                let appLanguageObject = settingsData.find(obj => obj.Property === "appLanguage")
                let appDateFormatObject = settingsData.find(obj => obj.Property === "appDateFormat")
                let appTimeFormatObject = settingsData.find(obj => obj.Property === "appTimeFormat")
    
                if (appLanguageObject) {
                    let language = appLanguageObject.Value
                    localStorage.setItem('language', language);
                } else localStorage.setItem('language', "en-GB");
    
                if (appDateFormatObject) {
                    let dateFormat = appDateFormatObject.Value
                    localStorage.setItem('DateFormat', dateFormat);
                } else localStorage.setItem('DateFormat', "YYYY-MM-DD");
    
                if (appTimeFormatObject) {
                    let timeFormat = appTimeFormatObject.Value
                    localStorage.setItem('TimeFormat', timeFormat);
                } else localStorage.setItem('TimeFormat', "24");
                fetchDictionary()
            } catch (error) {
                console.error('Failed to fetch settings', error);
            }
        }
        if (token !== '""' && token !== null) {
            localStorage.setItem('login', "loading");
             
             fetchUserData()
             
            

            
      
        }
    },[]);
    return <div className="App">
        <h1>Loggar in..</h1>
    </div>
}