import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { EventContext } from '../contexts/Context';
import { LargePlaceholder, getLabelText, setCalendarLanguage } from '../../functions/functions';
import { apiUrl, token } from '../../App'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { BlockPicker } from 'react-color';
import { binIcon, closeIcon, penIcon, plusIcon, checkIcon } from '../icons/icons';

// Komponent som visar dagschemat för ett event, hämtar det valda eventId från EventInfo
export function DayScheme({ selectedEventId, dayEvents, eventDate }) {
    const history = useHistory();
    const [selectedEvent] = useContext(EventContext);
    const [dayschemeEvents, setDayschemeEvents] = useState([]);
    const [selectedDayEvent, setSelectedDayEvent] = useState(null);
    const [selectedEventStart, setSelectedEventStart] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showDayEventInfo, setShowDayEventInfo] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [formTitle, setFormTitle] = useState(false);
    const [language, setLanguage] = useState(false);
    const [dayItems, setDayItems] = useState({
        Name: "",
        Start: "",
        End: "",
        Color: "",
        Details: "",
    });

    // funktion för att hämta data till dagschemat
    async function fetchDayData() {
        let eventId;

        if (selectedEvent) {
            eventId = selectedEvent.ID
        } else {
            eventId = localStorage.getItem("selectedEventId")
        }

        try {
            const response = await fetch(`${apiUrl}/schedule?token=${token}&id=${eventId}`);
            const data = await response.json();
            setDayschemeEvents(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // hämtar data när komponenten öppnas sätter rätt språk i kalendern
    useEffect(() => {
        fetchDayData()
        if (!dayEvents) {
            history.push(`/event/${localStorage.getItem("selectedEventId")}`)
        }
        else {
            setDayschemeEvents(dayEvents)
            setLoading(false)
        }
        setCalendarLanguage(setLanguage)
    }, []);

    // sätter rätt värden i schemat
    const fetchedDayEvents = dayschemeEvents.map(event => ({
        title: event.Name,
        start: event.Start,
        end: event.Stop,
        id: event.ID,
        backgroundColor: event.Color,
        extendedProps: { description: event.Details },
    }));

    // funktion för när man klickar på en händelse
    function handleEventClick(info) {
        
        setSelectedDayEvent(info.event);
        console.log(info.event.end)
        setDayItems({
            ID: info.event.id,
            Name: info.event.title,
            Start: info.event.start.toISOString().substring(0, 16),
            End: info.event.end.toISOString().substring(0, 16),
            Color: info.event.backgroundColor,
            Details: info.event.extendedProps.description,
        });
        if (selectedDayEvent === info.event) {
            setShowDayEventInfo(false)
        } else setShowDayEventInfo(true)
    };

    // funktion när man redigerar en händelse
    // postar ändringar till API:t
    async function handleEventChange(changeInfo) {

        const event = changeInfo.event;
        console.log(event)
        if(event.start < event.end){
            try {
                const response = await fetch(`${apiUrl}/schedule?token=${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        EvtID: selectedEventId,
                        ID: event.id,
                        Name: event.title,
                        Start: event.start,
                        Stop: event.end,
                        Details: event.extendedProps.description,
                        Color: event.backgroundColor,
                    }),
                });
    
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Could not edit event.');
                }
                else {
                    fetchDayData()
                }
    
            } catch (error) {
                console.error('Failed to edit event:', error);
                changeInfo.revert();
            }
        }else{
            alert("Start must be before end")
        }
      
    };

    // funktion för när man klickar på en tom yta i schemat eller på plustecknet
    function handleNewEventClick(date) {
        setDayItems({
            Name: "",
            Start: selectedEvent.Start,
            End: selectedEvent.End,
            Color: "#d9e3f0",
            Details: "",
        })
        setShowDayEventInfo(false)
        setShowForm(true);
        setFormTitle(getLabelText('app_editevent_AddDayEvent'))
    };

    // funktion för när man klickar på pennan
    function handleEditButtonClick() {
        setShowForm(true);
        setFormTitle(getLabelText('app_editevent_EditDayEvent'))
    }

    // komponent som visar info om händelsen
    const DayEventInfo = () => {
        if (!selectedDayEvent) {
            return null
        }

        return (
            <div className='dayevent-info'>
                <button className="close-button" onClick={() => setShowDayEventInfo(false)}>{closeIcon}</button>
                <h2>{selectedDayEvent.title}</h2>
                <p>{selectedDayEvent.extendedProps.description}</p>
                <div className="dayevent-info-buttons">
                    <button onClick={() => handleEditButtonClick()}>
                        {penIcon}
                    </button>
                    <p onClick={deleteDayEvent}>{binIcon}</p>
                </div>
            </div>
        );
    };

    // funktion för att ta bort en händelse
    async function deleteDayEvent() {
        try {
            const confirmed = window.confirm(getLabelText('app_eventedit_ConfirmDelete'))
            if (!confirmed) {
                return;
            }
            const response = await fetch(`${apiUrl}/schedule?token=${token}&id=${selectedDayEvent.id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error();
            } else {
                fetchDayData()
            }
        } catch (error) {
            console.error('Failed to delete event:', error);
        }
    };

    // funktion för när man avbryter redigering
    function onCancel() {
        setShowForm(false)
        setShowColorPicker(false)
    }

    // funktion för när man sparar en händelse
    const onSubmit = async (event) => {
        event.preventDefault();
        if(dayItems.Start < dayItems.End){
            try {
                const response = await fetch(`${apiUrl}/schedule?token=${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        EvtID: selectedEventId,
                        ID: dayItems.ID,
                        Name: dayItems.Name,
                        Start: dayItems.Start,
                        Stop: dayItems.End,
                        Details: dayItems.Details,
                        Color: dayItems.Color,
                    }),
                });
    
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Could not edit event.');
                } else {
                    setShowForm(false);
                    setShowDayEventInfo(false)
                    fetchDayData()
                }
    
            } catch (error) {
                console.error('Failed to edit event:', error);
            }
        }else{
            alert("Start must be before end")
        }
       
    };

    // funktion för att hantera ändringar i inputs
    function handleInputChange(event) {
        const { name, value } = event.target;

        setDayItems({
            ...dayItems,
            [name]: value,
        });
    };

    // funktion för när man ändrar färg
    function handleColorChange(color) {
        setDayItems({
            ...dayItems,
            Color: color.hex,
        });
    }

    // funktion för att visa/dölja färgväljaren
    function handleColorNameClick() {
        setShowColorPicker(!showColorPicker)
    }

    const timeFormat = localStorage.getItem('TimeFormat')
    const timeFormatOption = timeFormat === '12' ? true : false;

    return (
        <>
            <div className="Dayscheme">
                {loading ? (
                    <>
                        <LargePlaceholder />
                    </>
                ) : (
                    <>
                        {showForm && <form onSubmit={onSubmit}>
                            <div className='top-submit-buttons'>
                                <h2>{formTitle}</h2>
                                <div>
                                <button type="check">{checkIcon}</button>
                                <button onClick={() => onCancel()}>{closeIcon}</button>
                                </div>
                            </div>
                            <label>{getLabelText('app_evenedit_Name')}</label>
                            <input
                                name="Name"
                                value={dayItems.Name}
                                onChange={handleInputChange}
                                required
                            />
                            <label>{getLabelText('app_evenedit_StartDate')}</label>
                            <input
                                name="Start"
                                type="datetime-local"
                                value={dayItems.Start}
                                onChange={handleInputChange}
                                required
                            />
                            <label>{getLabelText('app_eventedit_EndDate')}</label>
                            <input
                                name="End"
                                type="datetime-local"
                                value={dayItems.End}
                                onChange={handleInputChange}
                                required
                            />
                            <label>{getLabelText('app_eventedit_Details')}</label>
                            <textarea
                                name="Details"
                                type="text"
                                value={dayItems.Details}
                                onChange={handleInputChange}
                            />
                            <label className='color-label' onClick={handleColorNameClick}>{getLabelText('app_eventedit_Colour')}
                                {!showColorPicker && (<div style={{ backgroundColor: dayItems.Color }}></div>)}
                            </label>
                            {showColorPicker && (
                                <BlockPicker
                                    color={dayItems.Color}
                                    onChange={handleColorChange}
                                    colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#ffd2d2']}
                                    required
                                />
                            )}
                        </form>}

                        {showDayEventInfo && <DayEventInfo />}
                        <div className='calendar'>
                            <div className='dayscheme-top'>
                                <button type="button" onClick={handleNewEventClick}>{plusIcon}<p>{getLabelText('app_eventedit_AddEvent')}</p></button>
                            </div>
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: '',
                                    center: 'title',
                                    right: 'prev,next'
                                }}
                                initialView="timeGridDay"
                                initialDate={eventDate}
                                locale={language}
                                
                                events={fetchedDayEvents}
                                eventContent={(event) => {
                                    console.log(event)
                                    const startDateTime = new Date(event.event.start);
                                    const endDateTime = new Date(event.event.end);
                                    const startTime = event.event.start.toTimeString().split(' ')[0].substring(0,5)
                                    const endTime = endDateTime.toTimeString().split(' ')[0].substring(0,5)
console.log(fetchedDayEvents)
                                    return (
                                        <div className="fc-event-main-frame">
                                            <div className="fc-event-time">{startTime}-{endTime}</div>
                                            <div className="fc-event-title-container">
                                                <div className="fc-event-title fc-sticky">
                                                    {event.event.title} <br></br>
                                                    <sub>{event.event.extendedProps.description}</sub>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                                editable={true}
                                eventStartEditable={true}
                                eventResizableFromStart={true}
                                snapDuration='00:05:00'
                                slotDuration='00:60:00'
                                slotLabelFormat={{
                                    hour: 'numeric',
                                    hour12: timeFormatOption
                                }}
                                eventClick={(info) => {
                                    handleEventClick(info);
                                }}
                                eventChange={handleEventChange}
                                eventAdd={handleEventChange}
                                dateClick={handleNewEventClick}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}