import React, { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ArtistContext } from '../contexts/Context';
import { apiUrl, token } from "../../App";
import { Input } from 'reactstrap';

// komponent där man väljer artist
function ArtistList() {
    const location = useLocation();
    const [artists, setArtists] = useState([]);
    const [selectedArtist, setSelectedArtist] = useContext(ArtistContext);
    const [selectedArtistId, setSelectedArtistId] = useState("");
    const [activeLocation, setActiveLocation] = useState(location.pathname);

    // funktion för att hämta lista på artister
    async function fetchArtists() {
        try {
            const response = await fetch(`${apiUrl}/Productions?token=${token}`);
            const data = await response.json();
            data.sort((a, b) => a.favorite - b.favorite);
            setArtists(data);

            setActiveLocation(location.pathname);
            if ((activeLocation.includes("events") || activeLocation.includes("tour") || activeLocation.includes("calendar")) && activeLocation.length > 40) {
                if(!activeLocation.slice(-36).includes("/"))
                localStorage.setItem("selectedArtistId", activeLocation.slice(-36))
            }

            const artistId = localStorage.getItem("selectedArtistId")

            // funktion för att kolla om ID:t finns i listan
            function checkArtistInList() {
                let idToFind = data.find(artist => artist.id === artistId);

                if (idToFind) {
                    setSelectedArtistId(idToFind.id);
                    setSelectedArtist(idToFind);
                    localStorage.setItem("selectedArtistId", idToFind.id);
                  
                } else {
                    setSelectedArtistId(data[0].id);
                    localStorage.setItem("selectedArtistId", data[0].id);
                    
                }
            }

            checkArtistInList()

            if (!artistId) { // om ingen artist är vald väljs automatiskt den första i listan
                setSelectedArtistId(data[0].id);
                localStorage.setItem("selectedArtistId", data[0].id);
                localStorage.setItem("selectedArtistName", data[0].name);
            }
        } catch (error) {
            console.error('Failed to fetch artist list', error);
        }
    };

    useEffect(() => {
        fetchArtists();
    }, []);

    // funktion för att hantera val av artist
    function handleArtistSelect(event) {
        const selectedId = event.target.value;
        setSelectedArtistId(selectedId);
        localStorage.setItem("selectedArtistId", selectedId);

        const selectedArtist = artists.find((artist) => artist.id === selectedId);
        setSelectedArtist(selectedArtist);
    };

    return (
        <Input type="select" className="artist-select" value={selectedArtistId} onChange={handleArtistSelect}>
            <option value="">
                Välj artist..
            </option>
            {artists.map((artist, index) => (
                <option key={index} value={artist.id}>
                    {artist.name}
                </option>
            ))}
        </Input>
    );
}

export default ArtistList;
