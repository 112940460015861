import React, { useState, useEffect, useContext } from "react";
import { apiUrl } from '../../App';
import { printIcon, saveIcon } from "../icons/icons";
import { getLabelText } from "../../functions/functions";

export function ApprovedGuests() {
  const [venue, setVenue] = useState()
  const [eventDate, setEventDate] = useState()
  const [guests, setGuests] = useState([]);

  console.log(guests)

  const eventId = localStorage.getItem("selectedEventId")

  async function fetchGuestList() {
    try {
      const response = await fetch(`${apiUrl}/guestlist?id=${eventId}`);
      const data = await response.json();
      console.log(data)
      setVenue(data.Venue)
      setEventDate(data.Date.split(' ')[0])
      setGuests(data.Guests);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGuestList()
    console.log(guests)
  }, []);

  const list = () => {
    console.log(guests)
    return guests.filter(guest => guest.Status === "0")
  }

  const downloadGuestList = () => {
    const guestList = list()
      .map(guest => `${guest.Name} ${guest.Extra > 0 && `+ ${guest.Extra}`}`)
      .join("\n");
    const blob = new Blob([guestList], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "guestlist.txt";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const printGuestList = () => {
    
    const guestList = list()
      .map(guest => `${guest.Name}`)
      .join("\n");
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`<h3>Gästlista</h3>
    <pre>${guestList}</pre>`);
    printWindow.print();
  };

  const totalGuests = list().reduce((acc, guest) => acc + 1 + Number(guest.Extra), 0)
  console.log(totalGuests)

  return (
    <div className="a-guest-list">
      <div className="personel-info-title">
        <h1>{venue}</h1>
        <h3>{eventDate}</h3>
      </div>
      <h2>{getLabelText('app_guestlist_ApprovedGuests')} {`(${totalGuests})`}</h2>
      <ul>
        {list().map((guest, index) => (
          <li key={index}>
            {guest.Name} {guest.Extra > 0 && `+ ${guest.Extra}`}
          </li>
        ))}
      </ul>
      <div className="buttons">
        <button title="save list" onClick={downloadGuestList}>{saveIcon}</button>
        <button title="print list" onClick={printGuestList}>{printIcon}</button>
      </div>
    </div>);
} 
