import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom/";
import { EventContext } from '../contexts/Context';
import { getLabelText, showMessage, getAttach } from '../../functions/functions';
import { apiUrl, token } from "../../App";
import { Editor } from '@tinymce/tinymce-react';
import { EventInfoHeader } from "../navs/Headers";
import { penIcon, checkIcon, closeIcon, fileIcon, binIcon, cancelIcon, okIcon } from '../icons/icons'
import {
    
    fromAddress,
    
  } from "react-geocode";
import {APIProvider, Map,Marker , Pin} from '@vis.gl/react-google-maps';
// komponent som visar info om ett event (generell, rese & hotel)
// hämtar noteringar och vald komponent från EventInfoblad
export function EventDetails({ note, selectedComponent, selectedEventID, venueData, venueInfo }) {
    const location = useLocation()
    const history = useHistory()
    const [selectedEvent] = useContext(EventContext);
  
    const [coordianates, setCoordianates] = useState({lat : 0, lng: 0});
    const [attachments, setAttachments] = useState(null);
    const [notes, setNotes] = useState(note);
  
    const [componentAttachments, setComponentAttachments] = useState(null);
    const [localNotes, setLocalNotes] = useState("");
    const [selectedEventId, setSelectedEventId] = useState(selectedEventID);
    const [isEditing, setIsEditing] = useState(false);
    const [isFileListVisible, setIsFileListVisible] = useState(false);
    const [fileList, setFileList] = useState(null);
    const [message, setMessage] = useState(null);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [editRight, setEditRight] = useState(localStorage.getItem("editRight"));
    const [component, setComponent] = useState(null);
    const [title, setTitle] = useState("");
    const [componentNotes, setComponentNotes] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [venueNotes, setVenueNotes] = useState("");
    const [venueInfoItems, setVenueInfoItems] = useState("");


    // nyckel för att visa google karta
    const mapKey = "AIzaSyCACrEA1gLrqLelj9JyNzOE4Olp0iFi-tc"

    // function för att hämta rätt noteringar
    async function fetchItinerary() {
        try {
            if (selectedEventId) {
                const response = await fetch(`${apiUrl}/Itinerary?id=${selectedEventId}&token=${token}`);
                const data = await response.json();
                setVenueNotes(data.VenueNotes)
                setComponentNotes(data.VenueNotes)
                setNotes(data.VenueNotes)
                
            } else {
                
      

                    setSelectedEventId(localStorage.getItem("selectedEventId"))
               
            
                const response = await fetch(`${apiUrl}/Itinerary?id=${selectedEventId}&token=${token}`);
                const data = await response.json();
                if (location.pathname.includes("eventdetails/")) {
                    setComponent("general")
                    setTitle(getLabelText('app_eventinfo_GeneralInfo'))
                    setComponentNotes(data.GeneralNotes)
                    setNotes(data.GeneralNotes)
                } else if (location.pathname.includes("travelinfo/")) {
                    setComponent("travel")
                    setTitle(getLabelText('app_eventinfo_TravelInfo'))
                    setComponentNotes(data.TravelNotes)
                    setNotes(data.TravelNotes)
                } else if (location.pathname.includes("hotelinfo/")) {
                    setComponent("hotel")
                    setTitle(getLabelText('app_eventinfo_HotelInfo'))
                    setComponentNotes(data.HotelNotes)
                    setNotes(data.HotelNotes)
                } else if (location.pathname.includes("venue/")) {
                    history.push(`/event/${localStorage.getItem("selectedEventId")}`)
                    
                }
            }
        } catch (error) {
            console.error('Failed to fetch itinerary', error);
        }
    };

    // sätter rätt värden beroende på vilken komponent som visas
    useEffect(() => {
        if (selectedComponent === "eventdetails") {
            setComponent("general")
            setTitle(getLabelText('app_eventinfo_GeneralInfo'))
        } else if (selectedComponent === "travelinfo") {
            setComponent("travel")
            setTitle(getLabelText('app_eventinfo_TravelInfo'))
        } else if (selectedComponent === "hotelinfo") {
            setComponent("hotel")
            setTitle(getLabelText('app_eventinfo_HotelInfo'))
        } else if (selectedComponent === "venue") {
            fetchItinerary()
            setComponent("venue")
            setTitle('Venue')
            setVenueInfoItems(venueInfo)

            // sätter rätt värden även om komponenten öppnas direkt via URLen 
        } else fetchItinerary()

        setNotes(note)
    }, [selectedComponent]);

    // funktion för att hämta filer
    async function fetchAttachments() {
        try {
            const response = await fetch(`${apiUrl}/Attachment?id=${selectedEventId}&token=${token}&container=ustage-uploads`);
            const data = await response.json();
            setAttachments(data);
        } catch (error) {
            console.error('Failed to fetch attachments', error);
        }
    };

    // funktion för att hämta filer beroende på komponent
    async function fetchComponentAttachments() {
        setComponentAttachments([]);
                
                const att = getAttach(`${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${component}`, 'ustage-upload')
                att.then((a) => {
                    a.forEach(i=>{
                        setComponentAttachments(oldArray => [...oldArray, i]);
                    })
                   
                })
                const att2 = getAttach(`${selectedEvent.ID}/${component}`, 'ustage-uploads')
                att2.then((a) => {
                    
                   a.forEach(i=>{
                    setComponentAttachments(oldArray => [...oldArray, i]);
                   })
                 
                })
           
            
                setLoadingFiles(false)
    };

    // hämtar rätt filer beroende på komponent
    useEffect(() => {

        if (component) {
            if (selectedComponent === "eventdetails") {
                setLoadingFiles(true)
                // fetchAttachments();
                fetchComponentAttachments()

            } else {
                setLoadingFiles(true)
                fetchComponentAttachments()
            }
        }

    }, [component]);

    // sätter aktuella noteringar som används i formulär
    useEffect(() => {
    
        setLocalNotes(notes);
        setLoading(false)
    }, [notes]);
    
    // funktion för när man redigerar ett event
    function handleNotesChange(content) {
     
        setLocalNotes(content);
        
    }

    // funktion för när man sparar redigering
    // postar ändringar till API:t
    function handleSaveClick() {
        setNotes(localNotes)
      
        setIsEditing(false);
       
        if (!selectedEventId) {
            console.error("Event ID not selected. Unable to update.");
            return;
        }

        let requestBody = {
            'EventID': selectedEventId,
        };

        if (selectedComponent === "eventdetails" || component === "event") {
            requestBody.GeneralNotes = localNotes;
        } else if (selectedComponent === "travelinfo"|| component === "travel") {
            requestBody.TravelNotes = localNotes;
        } else if (selectedComponent === "hotelinfo" || component === "hotel") {
            requestBody.HotelNotes = localNotes;
        } else if (selectedComponent === "venue" || component === "venue") {
            requestBody.VenueNotes = localNotes;
        }
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        fetch(`${apiUrl}/Itinerary?token=${token}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    }

    // funktion för när man avbryter redigering
    function handleCancelClick() {
        setLocalNotes(notes);
        setIsEditing(false);
    }

    // funktion för att ta bort fil
    async function handleAttachmentDelete(attachment) {
        // const url = `${apiUrl}/file?id=${selectedEventId}&name=${attachment}&token=${token}&container=ustage-uploads`;
        const url = `${apiUrl}/file?id=${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${component}&name=${attachment}&token=${token}&container=ustage-upload`;
        try {
            const response = await fetch(url, { method: 'DELETE' });
            if(response =="True")
                setComponentAttachments({attachments: this.state.attachments.concat([attachment])})
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.log(error.message);
        }
        console.log(`${attachment} fil borttagen`)
    }

    // funktion för att visa/dölja komponenten där man kan lägga till filer
    function toggleFileListVisibility() {
        setIsFileListVisible(!isFileListVisible);
        if (isFileListVisible) {
            setFileList(null);
        }
    };

    // komponent som visar "vanliga" filer
    const ShowAttachments = () => {
        return (
            <div>
                {attachments && attachments.length > 0 && (
                    <>
                        <ul>
                            {
                                attachments.map((attachment, index) => (
                                    <li key={index}>
                                        <a title="Ladda ner fil" href={`${apiUrl}/file?id=${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${component}&filename=${attachment}&token=${token}&container=ustage-upload`}>
                                            {attachment}
                                        </a>
                                        <button title="Radera fil" onClick={() => handleAttachmentDelete(attachment)}>{binIcon}</button>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )}
            </div>
        );
    }

    // komponent som visar komponentspecifika filer
    const ShowComponentAttachments = () => {
        return (

            <div>
                {componentAttachments && componentAttachments.length > 0 && (
                    <>
                        <ul>
                            {
                                componentAttachments.map((attachment, index) => (
                                    <li key={index}>
                                        <a title="Ladda ner fil" href={`${apiUrl}/file?id=${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${component}&filename=${attachment}&token=${token}&container=ustage-upload`}>
                                            {attachment}
                                        </a>
                                        <button title="Radera fil" onClick={() => handleAttachmentDelete(attachment)}>{binIcon}</button>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )}
            </div>
        );
    }

    // Komponent som hanterar vilka filer som ska visas
    const EventAttachments = () => {

        if (loadingFiles || !component) {
            return (
                <div className="attachments">
                </div>
            )
        } else if (selectedComponent === "eventdetails" && componentAttachments || attachments) {
            return (
                <div className="attachments">
                    <ShowAttachments />
                    <ShowComponentAttachments />
                </div>
            )
        } else if (componentAttachments) {
            return (
                <div className="attachments">
                    <ShowComponentAttachments />
                </div>
            )
        }
    };

    // komponenten där man kan lägga till filer
    const AddAttachments = () => {
        return (
            isFileListVisible && (
                <div className="add-attachments">
                    <div className="add-attachments-text">
                        <h3>{getLabelText('app_eventinfo_AddFiles')}</h3>
                        <button className="close-button" onClick={toggleFileListVisibility}>{cancelIcon}</button>
                    </div>
                    <div className="add-attachments-buttons">
                        <input type="file" onChange={handleFileChange} multiple />
                        <div className="upload">
                            <button className="check-button" onClick={handleUploadClick}>{okIcon}</button>
                            {isMessageVisible && <p>{message}</p>}
                        </div>
                    </div>
                    <ul>
                        {files.map((file, i) => (
                            <li key={i}>
                                {file.name} - {file.type}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        )
    }

    // funktion som hanterar val av fil
    function handleFileChange(e) {
        setFileList(e.target.files);
    };

    // funktion för när man lägger till en ny fil
    function handleUploadClick() {
        let eventId = selectedEvent ? selectedEvent.ID : localStorage.getItem("selectedEventId");

        if (!fileList || fileList.length === 0) {
            showMessage(setMessage, setIsMessageVisible, 'No file selected');
            return;
        }

        const data = new FormData();
        files.forEach((file, i) => {
            data.append(`file-${i}`, file, file.name);
        });
        // fetch(`${apiUrl}/file?id=${eventId}&token=${token}&container=ustage-uploads`, {
        fetch(`${apiUrl}/file?id=${eventId}/${component}&token=${token}&container=ustage-uploads`, {
            method: 'POST',
            body: data,
        })
            .then((res) => {
                if (res.ok) {
                    showMessage(setMessage, setIsMessageVisible, 'File uploaded');
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .catch((err) => console.error(err));
    };

    const files = fileList ? [...fileList] : [];

    // ikon för att redigera info (visas om man har rättigheter)
    const PenIcon = () => {
        if (editRight >= 3) {
            return (
                <button onClick={() => setIsEditing(true)}>{penIcon}</button>
            )
        } else {
            return null
        }
    }

    // ikon för att lägga till filer (visas om man har rättigheter)
    const FileIcon = () => {
        if (editRight >= 3) {
            return (
                <button onClick={toggleFileListVisibility}>{fileIcon}</button>
            )
        } else {
            return null
        }
    }

    // kopmonent som visar rätt noteringar beroende på komponent
    const RenderNotes = () => {
console.log(notes)
        if (notes) {
            
            return (
                
                <div dangerouslySetInnerHTML={{ __html: notes }} />
            )
        } else if (componentNotes) {

            return (
                <div dangerouslySetInnerHTML={{ __html: componentNotes }} />
            )
        } else return (
            null
        )
    }

    // komponent som visar info om venue med Google Maps
    const RenderVenueInfo = () => {

        if (venueInfoItems) {
            const address = venueInfoItems.find(item => item.FieldName === 't_Default-address');
            console.log(address.FieldValue)
   

            let encodedAddress = address ? encodeURIComponent(address.FieldValue) : '';
            fromAddress(address.FieldValue, mapKey).then(

                response => {
                
                const { lat, lng } = results[0].geometry.location; console.log(lat, lng);
                setCoordianates({lat:lat, lng:lng})
              
                }, error => { console.error(error); } ); 
            

            return (
                <div className="venueinfo">
                    <h2>{venueData.DisplayName}</h2>
                    {venueInfoItems
                        ?.filter(item => item.FieldValue && item.FieldValue.trim() !== '' && !item.FieldName.includes('dName'))
                        .map((item, index) => (
                            <div className="venue-item" key={index}>
                                {item.FieldGroup === "t_Default-ADDRESS" ? (
                                    <h3>{getLabelText(`${item.FieldName.replace('t_Default-', '')}`)}</h3>
                                ) : (
                                    <h3>{getLabelText(`${item.FieldName}`)}</h3>
                                )}
                                <p>: {item.FieldValue}</p>

                            </div>
                        ))}
                    <APIProvider apiKey={mapKey} >
                        <Map 
                        
                        defaultZoom={12}
                        
                        disableDefaultUI={true}
                        
                        center={{lat: coordianates.lat, lng: coordianates.lng}}
                        style={{width: '100%', height: '300px'}}
    
                        >
                            <Marker position={{lat: coordianates.lat, lng: coordianates.lng}}/>
                        </Map>
                      </APIProvider>
                </div>
            )
        } else return (
            <div className="event-details">
            </div>
        )
    }

    return (
        <>
            <EventInfoHeader />
            <div className='desktop-info'>
                <div className='list'>
                    <div className="list-title">
                        {isEditing ? (
                            <div>
                                <button onClick={handleSaveClick}>
                                    {checkIcon}
                                </button>
                                <button onClick={handleCancelClick}>
                                    {closeIcon}
                                </button>
                            </div>
                        ) : (
                                <PenIcon />
                        )}
                        <h1>{title}</h1>
                            <FileIcon />
                    </div>
                    {loading ? (
                        <div className='event-details'>
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <div className="event-details">
                                <AddAttachments />
                                {notes === "" && !isEditing ? (
                                    null
                                ) : (
                                    isEditing ? (
                                        <Editor
                                            apiKey="o8w8yovnikh9mmac3ofeqg07gjsolerihzstdts4z78f192k"
                                            value={localNotes}
                                            onEditorChange={handleNotesChange}
                                            init={{
                                                height: 700,
                                                menubar: false,
                                                plugins: [
                                                    'autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help'
                                            }}
                                        />
                                    ) : (
                                        <RenderNotes />
                                    )
                                )}
                                <EventAttachments />
                            </div>
                        </>
                    )}
                    {loading ? (
                        <div className="event-details">
                            <h3>{getLabelText('app_eventinfo_LoadingVenue')}</h3>
                        </div>
                    ) : (
                        (component === 'venue' || location.pathname.includes("venue/")) && <RenderVenueInfo />
                    )}
                </div>
            </div>
        </>
    )
}
