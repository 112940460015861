import React, { useState, useContext, useEffect } from 'react'
//useNavigate istället för useHistory
import { useHistory, useLocation } from 'react-router-dom';
import { EventContext } from '../contexts/Context';
import { getLabelText, formatHeaderDate,useWindowResize } from '../../functions/functions';
import ArtistList from "../misc/Artistlist";
import { arrowLeftIcon, headIcon } from '../icons/icons'
import {  desktopBreakpoint } from '../../App';
// header som används på diverse sidor när ett event inte är valt
export const PageHeader = () => {
    const location = useLocation();
    const history = useHistory();
    const [isShowSelect, setIsShowSelect] = useState(true);

    // titeln på sidan
    const HeaderTitle = () => {
        if (location.pathname.includes('settings')) {
            return <h1>{getLabelText('app_menu_Settings')}</h1>;
        } else if (location.pathname.includes('calendar')) {
            return <h1>{getLabelText('app_menu_Calendar')}</h1>;
        } else if (location.pathname.includes('tour')) {
            return <h1>{getLabelText('app_menu_Tourinfo')}</h1>;
        } else if (location.pathname.includes('person')) {
            return <h1></h1>;
        } else if (location.pathname.includes('event')) {
            return <h1>{getLabelText('app_menu_Events')}</h1>
        }
    }

    // funktion som visar/döljer namnet på artisten
    function showSelect() {
        setIsShowSelect(!isShowSelect);
    }
    const windowWidth = useWindowResize();
    // funktion för att gå tillbaka till föregående sida
    function backPage() {
        if (location.pathname.includes("events") || location.pathname.includes("calendar") || location.pathname.includes("tour")) {
            history.go(-1)
       
                // history.replace("/")
          
            
            console.log(history)
        } else history.go(-1)

        
    }

    return (
        <header >
            <button className="header-arrow" onClick={() => backPage()}>
                {arrowLeftIcon}
            </button>
            <div className="header-title">
                <HeaderTitle />
            </div>
            <div className={!isShowSelect ? "hidden" : "artist-select-header"}>
                <ArtistList />
            </div>
            <div title="Välj artist" className="artist-select-button" onClick={() => showSelect()}>Välj artist{headIcon}</div>
        </header>
    );
}

// header som visas på vissa komponenter när ett event är valt
export const EventInfoHeader = () => {
    const history = useHistory();
    const location = useLocation();
    const [selectedEvent, setSelectedEvent] = useContext(EventContext);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedEventName, setSelectedEventName] = useState(null);
    const [selectedEventTime, setSelectedEventTime] = useState(null);

    // sätter rätter info när komponenten öppnas
    useEffect(() => {
        if (selectedEvent) {
            setSelectedEventId(selectedEvent.ID)
            setSelectedEventName(selectedEvent.Name)
            setSelectedEventTime(selectedEvent.Start)
        } else {
            setSelectedEventId(null)
            setSelectedEventName(null)
            setSelectedEventTime(null)
            const eventId = localStorage.getItem("selectedEventId");
            setSelectedEventId(eventId);
        }
    }, [selectedEvent]);

    // Titeln som visar info om eventet
    const HeaderTitle = () => {
        return (
            <div className="header-title">
                {selectedEvent &&
                    <>
                        <h3>{selectedEventName}</h3>
                        <h3>{formatHeaderDate(selectedEventTime)}</h3>
                    </>
                }
            </div>
        )
    }

    // funktion för att gå tillbaka till föregående sida
    function backPage() {
        if (location.pathname.includes("events") || location.pathname.includes("/calendar") || location.pathname.includes("tour") ) {
            history.go(-2)
        } else history.go(-1)
    }

    return (
        <header>
            <button onClick={() => backPage()}>
                {arrowLeftIcon}
            </button>
            {selectedEvent && <HeaderTitle />}
            <p></p>
        </header>
    );

};