import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { ArtistContext, EventContext } from '../contexts/Context';
import { getLabelText } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { EventInfoHeader } from '../navs/Headers';
import { checkIcon,closeIcon, binIcon } from '../icons/icons';

// Komponent där man kan redigera eller lägga till ett event
export function EventEdit({ selectedDate, setSelectedDate, setShowForm }) {
    const history = useHistory();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState("");
    const [selectedArtist] = useContext(ArtistContext);
    const [selectedEvent] = useContext(EventContext);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedArtistId, setSelectedArtistId] = useState(localStorage.getItem("selectedArtistId"));
    const [formTitle, setFormTitle] = useState(false);
    const [checked, setChecked] = useState(false);
    const [eventForm, setEventForm] = useState({ProductionID: selectedArtistId,
        Start: `${selectedDate}T00:00`,
        Stop: `${selectedDate}T00:00`,
        AllDay: true,
        ProductionName: "",
        Type: "2",
        Subject: "",
        Description: "",
        SendToSubscribers: true,
        SendToSlack: false,
        Published: true,
        Status: "1",
        ID: "",
        Place: "",
        ContractSelected: false,
        GoToContract: false,});

    // sätter rätt värden beroende om ett event är valt eller inte när komponenten öppnas
    useEffect(() => {
        
        setCurrentLocation(location.pathname)
        console.log(selectedEvent)
        if (selectedEvent && window.innerWidth > 768) {
            setShowForm(true)
            setSelectedEventId(selectedEvent.ID);
            setFormTitle(getLabelText('app_eventedit_EditEvent'))
        } else if (selectedEvent && window.innerWidth < 768) {
            setSelectedEventId(selectedEvent.ID);
            setFormTitle(getLabelText('app_eventedit_EditEvent'))
        } else {
            setSelectedEventId(localStorage.getItem("selectedEventId"))
            setFormTitle(getLabelText('app_eventedit_AddEvent'))
        }

        if (selectedEvent && selectedEvent.StatusType === "1") {
            setSelectedEventId(selectedEvent.ID);
            setShowForm(false)
        }

        if (selectedEvent) {
            try {
                fetch(`${apiUrl}/Event?id=${selectedEvent.ID}&token=${token}`)
    .then(response => response.json())
    .then(function(response) {
        console.log(response)
        setEventForm({
            ProductionID: response.ProductionID,
            Start: response.Start,
            Stop: response.Stop,
            AllDay: response.AllDay,
            ProductionName: response.ProductionName,
            Type: response.Type,
            Subject: response.Subject,
            Description: response.Description,
            SendToSubscribers: response.SendToSubscribers,
            SendToSlack: response.SendToSlack,
            Published: response.Published,
            Status: "1",
            ID: response.ID,
            Place: response.Place,
            ContractSelected: false,
            GoToContract: false,
        });
        
        
    
    })
    .catch(function(error) {
        console.log(error);
    });    
              
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            
        } else {
            setEventForm({
                ProductionID: selectedArtistId,
                Start: `${selectedDate}T00:00`,
                Stop: `${selectedDate}T00:00`,
                AllDay: true,
                ProductionName: "",
                Type: "2",
                Subject: "",
                Description: "",
                SendToSubscribers: true,
                SendToSlack: false,
                Published: true,
                Status: "1",
                ID: "",
                Place: "",
                ContractSelected: false,
                GoToContract: false,
            });
        }
    }, [selectedEvent, selectedDate]);
    useEffect(()=>{
        setChecked(eventForm.AllDay)
        console.log(checked)
        
    },[eventForm])

    // funktion för att spara ändringar till API:t
    async function postFormData(formdata) {
        console.log(formdata)
        try {
            const response = await fetch(`${apiUrl}/formdata?token=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: "1",
                    Object: "event",
                    KV: formdata,
                }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Could not edit event.');
            }

        } catch (error) {
            console.error('Failed to edit event:', error);
        }
    };

    // funktion för att ta bort ett event från API:t
    async function deleteEvent() {

        try {
            const response = await fetch(`${apiUrl}/event?id=${selectedEventId}&token=${token}`, {
                method: 'DELETE'
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Could not delete event.');
            }

        } catch (error) {
            console.error('Failed to delete event:', error);
        }
    };

    // funktion som hanterar ändringar i inputs
   function handleInputChange(event) {
       
        if(event.target.name == "Start"){

            setEventForm({
                ...eventForm,
                [event.target.name]: event.target.value,
                ["Stop"]: event.target.value,
            });
        }
        else if(event.target.name == "Stop" && eventForm &&  event.target.value < eventForm.Start){
            setEventForm({
                ...eventForm,
                [event.target.name]: event.target.value,
                ["Start"]: event.target.value,
            });
        }
        else if(event.target.name == "AllDay" ){
            setEventForm({
                ...eventForm,
                [event.target.name]: event.target.checked
                
            });
        }
        else{
            setEventForm({
                ...eventForm,
                [event.target.name]: event.target.value,
            });
        }
    };

    // funktion för att dölja förmular
    function hideForm() {
        let artistId = localStorage.getItem("selectedArtistId")

        if (currentLocation.includes('/events') || currentLocation.includes('/calendar')) {
            history.push(currentLocation);
            setSelectedDate(null)
            // window.location.reload()
        } else {
            history.push(`/calendar/${artistId}`);
        } 
    }

    // funktion som hanterar förmuläret när det sparas
    function submitForm(e) {
        e.preventDefault();

        let formdata = []

        Object.keys(eventForm).forEach(key => {
            formdata.push({ Key: key, Value: eventForm[key] })
        });

        postFormData(formdata)
            .then(() => {
                hideForm()
            })
            .catch(err => {
                console.error(err);
            });
    }

    // funktion för när man avbryter redigering
    function handleCancel() {
        if (selectedDate) {
            setSelectedDate(null)
        } else if (selectedEvent && window.innerWidth > 768) {
            setShowForm(false)
        } else {
            setShowForm(false)
            history.goBack()
        }
    }

    // funktion för när man raderar ett event
    function handleDelete(e) {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this event")) {
            console.log("delete")
            deleteEvent().then(() => {
                // hideForm()
                setShowForm(false)
            })
            .catch(err => {
                console.error(err);
            });
        }
       
    }

    return (
        <>
            <EventInfoHeader />
            <div className="event-edit">
                <div className='list-title'>
                    <h2>{formTitle}</h2>
                    <button className="hide-button" onClick={() => handleCancel()}>{closeIcon}</button>
                </div>
                <form>
                    <label>{getLabelText('app_eventedit_Type')}</label>
                    <select
                        name="Type"
                        value={eventForm.Type}
                        onChange={handleInputChange}>
                        <option value="2">{getLabelText('t_Block')}</option>
                        <option value="3">{getLabelText('t_Other')}</option>
                    </select>
                    <label>{getLabelText('app_eventedit_Title')}</label>
                    <input
                        name="Subject"
                        value={eventForm.Subject}
                        onChange={handleInputChange}
                    />
                    <label>{getLabelText('app_eventedit_Place')}</label>
                    <input
                        name="Place"
                        value={eventForm.Place}
                        onChange={handleInputChange}
                    />
                       <label>{"AllDay"}</label>
                    <input
                
                        name="AllDay"
                        type='checkbox'
                        
                        checked={eventForm.AllDay}
                        onChange={handleInputChange}
                    />
                    {checked ? (
                        <>
    
        <label>{getLabelText('app_evenedit_StartDate')}</label>
    <input
        name="Start"
        type="date"
        value={eventForm.Start.substring(0,10)}
        onChange={handleInputChange}
        required
    />
    <label>{getLabelText('app_eventedit_EndDate')}</label>
    <input
        name="Stop"
        type="date"
        value={eventForm.Stop.substring(0,10)}
        onChange={handleInputChange}
        required
    /> </>
    
   
) : (
    <>
    <label>{getLabelText('app_evenedit_StartDate')}</label>
    <input
        name="Start"
        type="datetime-local"
        value={eventForm.Start}
        onChange={handleInputChange}
        required
    />
    <label>{getLabelText('app_eventedit_EndDate')}</label>
    <input
        name="Stop"
        type="datetime-local"
        value={eventForm.Stop}
        onChange={handleInputChange}
        required
    /> </>
)}
                    
                    <label>{getLabelText('app_eventedit_Details')}</label>
                    <textarea
                        name="Description"
                        value={eventForm.Description}
                        onChange={handleInputChange}
                    />
                    <div className='submit-buttons'>
                        <button onClick={submitForm}>{checkIcon}</button>
                        {selectedEvent ? (
                            <button onClick={handleDelete}>{binIcon}</button>
                        ) : (
                            <></>
                        )}
                    </div>
                </form>
            </div>
        </>
    )
}
