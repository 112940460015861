import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { format, isToday, isTomorrow, subDays, getDay } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/sv';
import da from 'date-fns/locale/da';

registerLocale('sv', sv)
registerLocale('no', no)
registerLocale('da', da)

const CustomCalendarIcon = () => (
  <img src='../icons/calendar.png' alt="Select date" />
);

export function MyDatePicker() {
  const [startDate, setStartDate] = useState(Date.now());
  const [focused, setFocused] = useState(false);
  const [country, setCountry] = useState('sv');

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

   const dateFormat = focused ? "yyyy-MM-dd" : (isToday(startDate) ? "'Idag' d MMMM" : (isTomorrow(startDate) ? "'Imorgon' d MMMM" : "EEEE d MMMM"));

  // const dateFormat = focused ? (country === 'no' ? "dd.MM.yyyy" : "yyyy-MM-dd") : (isToday(startDate) ? "'Idag' d MMMM" : (isTomorrow(startDate) ? "'Imorgon' d MMMM" : "EEEE d MMMM"));


  // const dateFormat = focused ? (country === 'no' ? "d. MMMM" : "yyyy-MM-dd") : (isToday(startDate) ? (country === 'no' ? "'idag' d. MMMM" : "'Idag' d MMMM") : (isTomorrow(startDate) ? (country === 'no' ? "'imorgen' d. MMMM" : "'Imorgon' d MMMM") : "EEEE d MMMM"));

  console.log(country)

  const handleChange = (date) => {
    setStartDate(date);
    setFocused(false);
  };

  return (
    <>
     {/* <div className="datepicker"> */}
      {/* <label className="date-headline">Datum</label> */}
      <div className="datepicker-input">
        {/* <CustomCalendarIcon /> */}
        <DatePicker
          dateFormat={dateFormat}
          placeholderText={country === 'no' ? "Velg dato" : "Välj datum"}
          showPopperArrow={true}
          showIcon={false}
          showTimeSelect
          selected={startDate}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          // filterDate={isWeekday}
          locale={country}
          minDate={subDays(new Date(), 0)}
        // withPortal
        />
        
      </div>
      {/* <div>
          <input type="radio" name="country" value="sv" checked={country === 'sv'} onChange={() => setCountry('sv')} />
          SV
        </div>
        <div>
          <input type="radio" name="country" value="no" checked={country === 'no'} onChange={() => setCountry('no')} />
          NO
        </div>
        <div>
          <input type="radio" name="country" value="da" checked={country === 'da'} onChange={() => setCountry('da')} />
          DA
        </div> */}
     {/* </div> */}
    </>
  )
}


