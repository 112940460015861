import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { EventContext } from '../contexts/Context';
import { SmallPlaceholder, formatDate2, getLabelText, useWindowResize, handleFold, handleClose, getArrowIcon, getAttach } from '../../functions/functions';
import { apiUrl, token, desktopBreakpoint } from '../../App';
import { EventInfoHeader } from "../navs/Headers";
import { EventDetails } from './EventDetails';
import { closeIcon, foldIcon, expandIcon, arrowRightIcon, infoIcon, travelIcon, hotelIcon, spinnerIcon } from '../icons/icons'

// komponent där man väljer vilket infoblad som ska visas (Eventdetails komponenten)
export function EventInfoblad() {
    const location = useLocation();
    const [selectedEvent] = useContext(EventContext);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const windowWidth = useWindowResize();
    const [isListOpened, setIsListOpened] = useState(true);
    const [isComponentOpened, setIsComponentOpened] = useState(true);
    const [itinerary, setItinerary] = useState(null);
    const [changedDate, setChangedDate] = useState(null);
    const [changedBy, setChangedBy] = useState(null);
    const [generalNotes, setGeneralNotes] = useState(null);
    const [travelNotes, setTravelNotes] = useState(null);
    const [hotelNotes, setHotelNotes] = useState(null);
    const [generalAttachments, setGeneralAttachments] = useState(false);
    const [travelAttachments, setTravelAttachments] = useState(false);
    const [hotelAttachments, setHotelAttachments] = useState(false);
    const [notes, setNotes] = useState(null);
    const [loading, setLoading] = useState(true);

    // hämtar info från API när komponenten öppnas
    useEffect(() => {
        let selectedEventId = null;

        if (selectedEvent) {
            selectedEventId = selectedEvent.ID;
        } else {
            selectedEventId = localStorage.getItem("selectedEventId");
        }

        setSelectedEventId(selectedEventId);
        fetchAttachments()
        function fetchAttachments() {
           
            var list = ["general", "travel", "hotel"]
            list.forEach(x=>{
                const att = getAttach(`${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${x}`, 'ustage-upload')
                att.then((a) => {
                    if(x=="general" && a.length >0)
                        setGeneralAttachments(true)
                        if(x=="travel" && a.length >0)
                            setTravelAttachments(true)
                        if(x=="hotel" && a.length >0)
                            setHotelAttachments(true)
                })
                
                const att2 = getAttach(`${selectedEvent.ID}/${x}`, 'ustage-uploads')
                att2.then((a) => {
                    if(x=="general" && a.length >0)
                        setGeneralAttachments(true)
                        if(x=="travel" && a.length >0)
                            setTravelAttachments(true)
                        if(x=="hotel" && a.length >0)
                            setHotelAttachments(true)
                })
                
          
            })
          
        };
        async function fetchItinerary() {
            try {
                const response = await fetch(`${apiUrl}/Itinerary?id=${selectedEventId}&token=${token}`);
                const data = await response.json();
              
                setItinerary(data);
                setGeneralNotes(data.GeneralNotes)
                setTravelNotes(data.TravelNotes)
                setHotelNotes(data.HotelNotes)
                setLoading(false)
            } catch (error) {
                console.error('Failed to fetch itinerary', error);
            }
        };

        let fetchTimeout;
        if (selectedEventId) {
            clearTimeout(fetchTimeout)
            fetchTimeout = setTimeout(() => {
                fetchItinerary()
            }, 100)
        }

        return () => {
            clearTimeout(fetchTimeout)
        }

    }, [selectedEvent]);

    // visar vem som ändrat infobladet senast om infoblad finns 
    useEffect(() => {
        if (itinerary) {
            setChangedDate(itinerary.Changed)
            setChangedBy(itinerary.ChangedBy)
        }
        console.log(changedDate)
    }, [itinerary]);

    // funktion som hanterar vilken komponent som ska öppnas
    function handleNavLinkClick(component) {

        if (component === selectedComponent) {
            setSelectedComponent(null);
            setNotes(null);
            window.history.pushState({}, "", `/infoblad/${selectedEventId}`);
        } else {
            setSelectedComponent(component);
            setNotesByComponent(component);
            window.history.pushState({}, "", `/${component}/${selectedEventId}`);
        }
    };

    // funktion som sätter rätt noteringar beroende på komponent
    function setNotesByComponent(selectedComponent) {
        if (selectedComponent === "eventdetails") {
            setNotes(generalNotes)
        } else if (selectedComponent === "travelinfo") {
            setNotes(travelNotes)
        } else if (selectedComponent === "hotelinfo") {
            setNotes(hotelNotes)
        }
    }

    // visar vald komponent
    function renderSelectedComponent() {
        console.log(selectedComponent)
        console.log(notes)
        switch (selectedComponent) {
            case 'eventdetails':
                return <EventDetails note={notes} selectedComponent={selectedComponent} selectedEventID={selectedEventId} />;
            case 'travelinfo':
                return <EventDetails note={notes} selectedComponent={selectedComponent} selectedEventID={selectedEventId} />;
            case 'hotelinfo':
                return <EventDetails note={notes} selectedComponent={selectedComponent} selectedEventID={selectedEventId} />;
        }
    };

    const ListTitle = () => {
        return (
            <div className='list-title'>
                <button className="hide-button" onClick={() => handleFold(isListOpened, setIsListOpened)}>{getArrowIcon(isListOpened, foldIcon, expandIcon)}</button>
                <h1>{`${isListOpened ? getLabelText('app_eventinfo_Itinerary') : ''}`}</h1>
                <div className="event-title-icons">
                    <button className="hide-button" onClick={() => handleClose(setIsComponentOpened)}>{closeIcon}</button>
                </div>
            </div>
        )
    }

    const InfoSelector = () => {
        if (loading) {
            return (
                <>
                    <div className={`${isListOpened ? 'eventinfo-list' : 'small-list'}`}>
                        <ListTitle />
                        <SmallPlaceholder />
                    </div>
                </>
            )
        } else return (
            <>
                <div className={`${isListOpened ? 'eventinfo-list' : 'small-list'}`}>
                    <ListTitle />
                    <div className="nav-link" onClick={() => handleNavLinkClick('eventdetails')}>
                        <li className={selectedComponent === 'eventdetails' ? 'eventinfo-selected' : generalNotes || generalAttachments ? 'eventinfo' : 'empty-eventinfo'}>
                            <h2>{infoIcon} {getLabelText('app_eventinfo_GeneralInfo')}</h2>{arrowRightIcon}
                        </li>
                    </div>
                    <div className="nav-link" onClick={() => handleNavLinkClick('travelinfo')}>
                        <li className={selectedComponent === 'travelinfo' ? 'eventinfo-selected' : travelNotes || travelAttachments? 'eventinfo' : 'empty-eventinfo'}>
                            <h2>{travelIcon} {getLabelText('app_eventinfo_TravelInfo')}</h2>{arrowRightIcon}
                        </li>
                    </div>
                    <div className="nav-link" onClick={() => handleNavLinkClick('hotelinfo')}>
                        <li className={selectedComponent === 'hotelinfo' ? 'eventinfo-selected' : hotelNotes || hotelAttachments ? 'eventinfo' : 'empty-eventinfo'}>
                            <h2>{hotelIcon} {getLabelText('app_eventinfo_HotelInfo')}</h2>{arrowRightIcon}
                        </li>
                    </div>
                    <div className='changed-info'>
                        <p>{getLabelText('app_eventinfo_LastEdited')} {`${changedBy ? `${getLabelText('app_eventinfo_By')} ${changedBy}` : ''}`}</p>
                        <p>{formatDate2(changedDate)}</p>
                    </div>
                </div>
            </>
        )
    }

    // komponenter visas på olika sätt på desktop och mobil
    if (windowWidth > desktopBreakpoint) {
        return (
            <>
                <EventInfoHeader />
                <div className={`${isComponentOpened ? 'desktop-info' : 'hidden'}`}>
                    <InfoSelector />
                    <div className='selected-component'>
                        {renderSelectedComponent()}
                    </div>
                </div>
            </>
        )
    } else if (windowWidth < desktopBreakpoint && selectedComponent) {
        return (
            <>
                <EventInfoHeader />
                <div className='eventinfo-list-mobile'>
                    {renderSelectedComponent()}
                </div>
            </>
        )
    } else if (windowWidth < desktopBreakpoint) {
        return (
            <>
                <EventInfoHeader />
                <InfoSelector />
            </>
        )
    }
}