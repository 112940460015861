import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { ArtistContext, EventContext } from '../contexts/Context';
import { getLabelText } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { checkIcon, closeIcon, penIcon, circleIcon, dragIcon, arrowDownIcon, arrowUpIcon, okIcon, binIcon ,plusIcon} from "../icons/icons";

// komponent som visar info om personal för ett event
export function PersonelInfo({ personelData, projectData, selectedEventId }) {
  const history = useHistory();
  const [selectedEvent, setSelectedEvent] = useContext(EventContext);
  const [selectedArtistId] = useContext(EventContext);
  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editRight, setEditRight] = useState(localStorage.getItem("editRight"));
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [changesToSave, setChangesToSave] = useState(new Set());
  const [personel, setPersonel] = useState([]);
  const [addPersonel, setAddPersonel] = useState([]);
  const [checkedPersonel, setCheckedPersonel]=useState([]);
  const [filter, setFilter] = useState('present');
  const [shownProjects, setShownProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [groupedPersons, setGroupedPersons] = useState({
    present: [],
    uncertain: [],
    notPresent: []
  })
  
  // kollar om info finns när komponenten öppnas
  let eventId;
  if (selectedEventId) {
    eventId = selectedEventId
  } else {
    eventId = localStorage.getItem("selectedEventId")
  }
  // async function fetchRights() {
  //   try {
  //     const artistId = localStorage.getItem("selectedArtistId");
  //     const response = await fetch(`${apiUrl}/UserProductionRights?id=${artistId}&date=${selectedEvent.Start}&token=${token}`);
  //     const data = await response.json();
  //     setEditRight(data)
  //     localStorage.setItem("editRight")
    
  //   } catch (error) {
  //     console.error('Failed to fetch edit rights', error);
  //   }
  // };

  // hämtar data om personal
  async function fetchPersonelData() {
  
    try {
      const response = await fetch(`${apiUrl}/EventPerson?id=${eventId}&token=${token}`);
      const data = await response.json();
     
      setPersonel(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!personelData) {
      history.push(`/event/${eventId}`)
    } else fetchPersonelData()


    
  }, []);

  function getArrowIcon(showDetails) {
    return showDetails ? arrowUpIcon : arrowDownIcon;
  };

  function toggleProject(projectName) {
    setShownProjects(prevState => ({
      ...prevState,
      [projectName]: !prevState[projectName]
    }))
  }

  function transformPersonForAPI(person) {
    return {
      Comment: "",
      FirstName: person.FirstName,
      LastName: person.LastName,
      PersonID: person.PersonID,
      SortOrder: 0,
      Status: person.Type === 't_Standard' ? 0 : 2,
      Title: person.Title,
    }
  }

  // komponent som visar lista på närvarande personal
  const ProjectInfo = () => {
    // if (personel.length > 0) {

    const groupedByStatus = personel.reduce((acc, person) => {
      if (!acc[person.Status]) {
        acc[person.Status] = []
      }
      acc[person.Status].push(person)

      return acc
    }, {})

    return (
      <>
        <div className="personel-info">
          {Object.entries(groupedByStatus).map(([status, persons]) => (
            //  {personel.filter(person => person.Status === 0).map(person => (
            <ul className="projectinfo" key={status}>
              <h3 className="project-title">{status === "0" ? getLabelText('app_personel_present') :
                status === "1" ? getLabelText('app_personel_Uncertain') :
                  status === "2" ? getLabelText('app_personel_NotPresent') : status}</h3>
              {persons.map(person => (
                <li className="project-person" key={person.PersonID}>
                  <h4 onClick={() => {
                    if (selectedPersonId === person.PersonID) {
                      setSelectedPersonId(null);
                    } else {
                      setSelectedPersonId(person.PersonID);
                      history.push(`/person/${person.PersonID}`);
                    }
                  }}>
                    {person.FirstName} {person.LastName}
                  </h4>
                  <p>{person.Title}</p>
                </li>
              ))}
            </ul>
          ))}
        </div >
      </>
      //   );
      //  }
      // else return (
      //   <div className="personel-info">
      //     <p>Ingen personal hittad, vill du lägga till personer från någon av dina projekt?</p>
      //     <ProjectPersonelInfo />
      //   </div>
    )
  };

  useEffect(() => {
    
    const grouped = personel.reduce((groups, person) => {
      if (person.Status === 0) {
        groups.present.push(person)
      } else if (person.Status === 1) {
        groups.uncertain.push(person)
      } else if (person.Status === 2) {
        groups.notPresent.push(person)
      }
      return groups
    }, {
      present: [],
      uncertain: [],
      notPresent: []
    })
    
      setGroupedPersons(grouped)
      setLoaded(true)
    
    
  }, [selectedEvent, personel]);

  const ProjectPersonelInfo = () => {
    
    const today = new Date();

    const groupedProjects = projectData
      .reduce((grouped, project) => {
        const startDate = new Date(project.Start);
        const stopDate = new Date(project.Stop);

        if (
          (filter === 'past' && stopDate < today) ||
          (filter === 'present' && startDate <= today && today <= stopDate) ||
          (filter === 'upcoming' && today < startDate)
        ) {
          const key = project.ProjectName;
          if (!grouped[key]) {
            grouped[key] = [];
          }
          grouped[key].push(project);
        }

        return grouped;
      }, {});
    
    // const transformedPersons = Object.values(groupedProjects).flat().map(transformPersonForAPI)

    async function updateProjectId() {
      const ID = Object.values(groupedProjects)[0][0].ProjectID

      try {
        const response = await fetch(`${apiUrl}/ChangeField?id=${selectedEventId}&type=event&value=${ID}&field=ProjectID&locked=false&token=${token}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }
        else {
          ("Data succesfully updated");
        }

      } catch (error) {
        console.log('Error updating data', error)
      }
    }
    
    // setGroupedPersons(transformedPersons)
    function addProjectPersons(name) {
      setPersonel(Object.values(groupedProjects[name]).flat().map(transformPersonForAPI))
      updateProjectId()
 
    }
    function addProjectPerson(person) {
    
      updateProjectId()
    }
    function addPersonelCheckbox(person,checked){
      let list = Object.values(groupedProjects).flat().map(transformPersonForAPI)
      let unique = [];
      list.forEach(element => {
          if (!unique.some(x=>x.PersonID == element.PersonID)) {
              unique.push(element);
          }
      });
      list = unique
      // console.log(Object.values(groupedProjects).flat().map(transformPersonForAPI))
      list = list.filter(x=> checkedPersonel.some(per => per ==x.PersonID) )
     
      setPersonel(list)
      
      // setPersonel
    }
    const handleCheckboxChange = (event) => {
      const checkedId = event.target.value;
     
      if(event.target.checked){
        setCheckedPersonel([...checkedPersonel,checkedId])
      }else{
        setCheckedPersonel(checkedPersonel.filter(id=>id !== checkedId))
      }
    
    }


    if (projectData.length > 0) {
      return (
        <>
          <div className="personel-info-title">
            {/* <h2>{getLabelText('app_tour_YourProjects')}</h2> */}
            {/* <select value={filter} onChange={e => setFilter(e.target.value)}>
            <option value="past">{getLabelText('app_tour_Past')}</option>
            <option value="present">{getLabelText('app_tour_Current')}</option>
            <option value="upcoming">{getLabelText('app_tour_Upcoming')}</option>
          </select> */}
          </div>
          {Object.entries(groupedProjects).map(([projectName, projects,projectID], index) => (
            <ul className="projectinfo" key={index}>
              <div className="project-title" onClick={() => toggleProject(projectName)}>
                <h3>{projectName}</h3>
                {getArrowIcon(shownProjects[projectName])}
              </div>
              {shownProjects[projectName] && (
                <>
                  {projects.map((person, i) => (
                     
                    <li className="project-person" style={{flex:"auto"}} key={i}>
                     <div>
                     <h4 onClick={() => {
                        if (selectedPersonId === person.PersonID) {
                          setSelectedPersonId(null);
                          localStorage.setItem('selectedPerson', null);
                        } else {
                          setSelectedPersonId(person.PersonID);
                          localStorage.setItem('selectedPerson', person.PersonID);
                          history.push(`/person/${person.PersonID}`);
                        }
                      }}>
                        {person.FirstName} {person.LastName}
                      </h4>
                      <p>{person.Title}</p>
                     </div>
                     
               
                      
                      <input type="checkbox" checked={checkedPersonel.includes(person.PersonID) || personel.some(x=> x.PersonID == person.PersonID)} value={person.PersonID} onChange={ handleCheckboxChange }   />
                      
                      
                    </li>
                  ))}
                  
                  <div className="add-personel">
                    <button className="confirm-button" onClick={() => addProjectPersons(projectName)}><p>Add all</p></button>
                  </div>
                </>
              )}
            </ul>
          ))}
          <button className="confirm-button" style={{marginBottom : 2}} onClick={() => addPersonelCheckbox()}><p>Add selected</p></button>
        </>
      )
    } else {
      return (
        <div className="personel-info">
          <p>{getLabelText('app_tour_NoProjects')}</p>
        </div>
      )
    }
  };

  // funktion för att flytta personal
  function onDragEnd(result) {
    const { source, destination } = result
    if (!destination) return;
    if (source.droppableId === destination.droppableID && source.index === destination.index) return;

    setGroupedPersons(prev => {
      const updated = { ...prev }
      const [removed] = updated[source.droppableId].splice(source.index, 1)
      updated[destination.droppableId].splice(destination.index, 0, removed)

      updated[destination.droppableId] = updated[destination.droppableId].map((person, index) => {
        if (person.SortOrder !== index || person.Status !== getStatusFromDroppableId(destination.droppableId)) {
          // setChangesToSave(prevChanges => new Set(prevChanges).add(person.PersonID))
        
          person.Status = getStatusFromDroppableId(destination.droppableId);
          person.SortOrder = index;
          return person
        }else{
         
          return person
        }
        
      })
      return updated
    })
   
  
  }

  function getStatusFromDroppableId(droppableId) {
    switch (droppableId) {
      case 'present':
        return 0;
      case 'uncertain':
        return 1;
      case 'notPresent':
        return 2;
    }
  }

  function toggleSelectedPerson(id) {
    setSelectedPersons(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(personId => personId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const Checkbox = ({ id, isSelected, onToggle }) => {
    if (showCheckbox) {
      return (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onToggle(id)}
        />
      )
    } else return null
  }

  function handleDeleteSelectedPersons() {
    setPersonel(prevPersons => prevPersons.filter(person => !selectedPersons.includes(person.PersonID)))
 
    setSelectedPersons([])
  
    // handleSave()
  }
  function deletePersonel(perID){

    setCheckedPersonel(checkedPersonel.filter(id=>id !== perID))
    setPersonel(personel.filter(per=>per.PersonID !== perID))
   

    setGroupedPersons((prev) =>{
      var updated = prev;
      updated.present = updated.present.filter(per=>per.PersonID !== perID)
      updated.notPresent = updated.notPresent.filter(per=>per.PersonID !== perID)
      updated.uncertain = updated.uncertain.filter(per=>per.PersonID !== perID)
    
      return updated
    } )
 
   
  }
  // komponenten där man kan flytta personal
  const PersonelEdit = () => {
    if (personel.length > 0) {
      return (
        <>
        <div className="personel-edit">
          <DragDropContext onDragEnd={onDragEnd}>
            {['present', 'uncertain', 'notPresent'].map(status => (
              <Droppable key={status} droppableId={status} direction="vertical">
                {(provided) => (
                  <div className="status-section" {...provided.droppableProps} ref={provided.innerRef}>
                    <h3>{status === 'present' ? getLabelText('app_personel_present') : status === 'notPresent' ? getLabelText('app_personel_NotPresent') : getLabelText('app_personel_Uncertain')}</h3>
                    {groupedPersons[status].map((person, i) => (
                      <Draggable key={person.PersonID.toString()} draggableId={person.PersonID.toString()} index={i}>
                        {(provided) => (
                          <div className="project-person-parent" ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                            <div className='project-person-edit'>
                              <div className={status}>
                                <h3>{circleIcon} {person.FirstName} {person.LastName}</h3>
                                <p>{person.Title}</p>
                              </div>
                              {dragIcon}
                            </div>
                            <button style={{backgroundColor:"transparent"}}  onClick={()=>{deletePersonel(person.PersonID)}}>{binIcon}</button> 
                            {/* <Checkbox
                              id={person.PersonID}
                              isSelected={selectedPersons.includes(person.PersonID)}
                              onToggle={toggleSelectedPerson} /> */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
         
          
        </div>
        <div className="personel-info">
       <ProjectPersonelInfo />
       </div>
         
          </>
        
      );
    } else return (
      <div className="personel-info">
        <p>{getLabelText('app_personel_AddProject')}</p>
        <ProjectPersonelInfo />
        
      </div>
    )
  };
  useEffect(() => {
    if(loaded)
    handleSave()
}, [groupedPersons]);
  //funktion för när man sparar ändringar
  async function saveChanges() {
    console.log(groupedPersons)
    const updatedPersonelList = [].concat(groupedPersons.present, groupedPersons.notPresent, groupedPersons.uncertain)
   
    try {
      const response = await fetch(`${apiUrl}/EventPerson?id=${selectedEvent.ID}&token=${token}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPersonelList),
      });

      
      if (!response.ok) {
        throw new Error(data.message || 'Could not update list.');
      }
      else {
        console.log("Data succesfully updated");
      }
      setChangesToSave(new Set())

    } catch (error) {
      console.log('Error updating data', error)
    }
    
  }


  async function handleSave() {
    saveChanges()
    // setIsEditing(false);
  }

  function handleCancel() {
    setPersonel(personel)
    setIsEditing(false);
    setShowCheckbox(false)
  }

  const RenderInfo = () => {
    if (personel) {
      return (
        <ProjectInfo />
      )
    } else return null
  }

  return (
    <div className='desktop-info'>
      <div className="list">
        <div className="list-title">
          {/* {editRight >=3 ? (
            <div>
              <button onClick={handleSave}>
                {checkIcon}
              </button>
              <button onClick={handleCancel}>
                {closeIcon}
              </button>
            </div>
          ) : (
            <div className="setlist-title-buttons">
              <button onClick={() => setIsEditing(true)}>{penIcon}</button>
            </div>
          )} */}
          <h1>{getLabelText('app_eventinfo_Personnel')}</h1>
          {editRight >=3 ? (
            <div>
              <div className="setlist-title-buttons">
                {selectedPersons.length > 0 && <button onClick={handleDeleteSelectedPersons}>{okIcon}</button>}
                {/* <button onClick={() => setShowCheckbox(!showCheckbox)}>{binIcon}</button> */}
              </div>
            </div>
          ) : (
              <div className="setlist-title-buttons">
              </div>
          )}
        </div>
        {editRight >=3 ? (
          <PersonelEdit />
        ) : (
          <RenderInfo />
        )}
      </div>
    </div>
  );
}