import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';

// komponent för att guida användaren genom olika sidor
export const useJoyride = (steps) => {
  const [run, setRun] = useState(false);

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLogin');
    if (!firstLogin) {
      setRun(true);
    }
  }, []);

  const startTour = () => {
    setRun(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      localStorage.setItem('firstLogin', 'false');
      setRun(false);
    }
  };

  const joyride = (
    <Joyride
      steps={steps}
      run={run}
      continuous={true}
      scrollToFirstStep={false}
      showProgress={true}
      showSkipButton={true}
      hideCloseButton={true}
      
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );

  return [joyride, startTour];
};
