import React, { useState, useEffect } from 'react';
import { getLabelText, showMessage } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { binIcon, copyIcon, subscribeIcon } from '../icons/icons';
import { Button } from 'reactstrap';

// komponent där man kan hämta iCal-länk
export default function ICalModal({ isICalModalOpen }) {
    const [ICalLinks, setICalLinks] = useState([]);
    const [message, setMessage] = useState(null);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [artist, setArtist] = useState("");
    const artistId = localStorage.getItem("selectedArtistId");

    async function fetchiCal() {
        try {
            const response = await fetch(`${apiUrl}/iCal?productionID=${artistId}&token=${token}`);
            const data = await response.json();
            if (data) {
                const latest = data.reduce((a, b) => {
                    return new Date(a.EndDate) > new Date(b.EndDate) ? a : b;
                  });
                  
              
                setICalLinks([latest]);
                //om man vill visa all länkar
                setICalLinks(data);
                // setArtist(latest.Name)
                
            }
        } catch (error) {
            console.error('Failed to fetch iCal data', error);
        }
    };
    async function deleteIcal(id) {
        try {
            const response = await fetch(`${apiUrl}/iCal?id=${id}&token=${token}`,{method: 'DELETE'});
            const data = await response.json();
            if (data) {
                console.log("deleted")
                fetchiCal()
                
            }
        } catch (error) {
            console.error('Failed to delete iCal data', error);
        }
      
    };

    useEffect(() => {
        if (artistId) fetchiCal();
    }, [artistId]);

    return (
        <div style={{ display: isICalModalOpen ? "block" : "none" }}>
            <div className={`${isICalModalOpen ? "ical-modal" : "hidden"}`}>
                <h2>{getLabelText('app_menu_IcalLinks')}</h2>
                {ICalLinks.map((link, index) => (
                  <div key={index}>
                    <p >{link.Name}</p>
                    <div className='ical-buttons' >
                        <button className='subscribe-button'><a href={link.URL}  target="_blank" rel="noopener noreferrer">{getLabelText('app_ical_Subscribe')}</a> {subscribeIcon}</button>
                        <CopyToClipboard text={link.URL}
                            onCopy={() => showMessage(setMessage, setIsMessageVisible, "iCal-länk kopierad")}>
                            <p title={getLabelText('app_ical_CopyLink')}>{copyIcon}</p>
                        </CopyToClipboard>
                        {/* om man vill visa end date */}
                        {/* <p>{new Date(link.EndDate).getFullYear()}</p> */}
                        <button onClick={() =>deleteIcal(link.ID)} title='radera länk'>{binIcon}</button>
                    </div>
                  </div>
                ))}
                {isMessageVisible && <p className='message'>{message}</p>}
            </div>
        </div>
    );
}