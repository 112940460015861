import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getLabelText } from '../../functions/functions';
import { apiUrl, token } from "../../App";
import { PageHeader } from "../navs/Headers";

// komponent som visar info om en person i ett projekt
export function PersonInfo() {
    const { id } = useParams();
    const [personData, setPersonData] = useState(null);

    async function fetchPersonData() {
        try {
            const response = await fetch(`${apiUrl}/Object?id=${id}&token=${token}`);
            const data = await response.json();
            console.log(data);
            setPersonData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchPersonData();
    }, [id]);

    if (!personData) return null;

    const personInfoItems = personData.Fields

    return (<>
        <PageHeader />
        <div className="Tour">
            <div className="tourinfo">
                <h2>{personData.DisplayName}</h2>
                {personInfoItems
                    ?.filter(item => item.FieldValue && item.FieldValue.trim() !== '' && !item.FieldName.includes('pndFirstName') && !item.FieldName.includes('pndLastName'))
                    .map((item, index) => (
                        <div className="personinfo-item" key={index}>
                            {
                                item.FieldGroup === "t_Default-ADDRESS" ? (
                                    <h3>{getLabelText(`${item.FieldName.replace('t_Default-', '')}`)}</h3>
                                ) : (
                                    <h3>{getLabelText(`${item.FieldName}`)}</h3>
                                )}
                            <p>: {item.FieldGroup === "EMAIL" ? (
                                <a href={`mailto:${item.FieldValue}`}>{item.FieldValue}</a>
                            ) : item.FieldGroup === "PHONE" ? (
                                <a href={`tel:${item.FieldValue}`}>{item.FieldValue}</a>
                            ) : (
                                item.FieldValue
                            )}
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    </>
    )
}