import React from 'react'
import { getLabelText, handleLogout } from '../../functions/functions';

// komponent som hanterar utloggning
export function Logout() {
    return (
      <div className='logout'>
        <button className="logout-button" onClick={handleLogout}>{getLabelText('t_Logout')}</button>
        </div>
    )
  }
  