import React, { useContext, useState, useEffect, useCallback, Component } from 'react';
import { useHistory } from 'react-router-dom';
import { ArtistContext, EventContext } from '../contexts/Context';
import { checkToken, formatHeaderDate, formatEventDate, getLabelText, useWindowResize, Placeholder, handleLogout } from '../../functions/functions';
import { apiUrl, token, desktopBreakpoint } from '../../App';
import { PageHeader } from "../navs/Headers";
import { EventInfo } from '../eventinfo/EventInfo';
import ICalModal from '../misc/ICalModal';
import { useJoyride } from "../misc/Joyride";
import { arrowRightIcon, filterIcon, questionIcon, shareIcon } from '../icons/icons'

// sida som visar kommande event
export function EventList() {
  const history = useHistory();
  const [selectedArtist] = useContext(ArtistContext);
  const [selectedEvent, setSelectedEvent] = useContext(EventContext);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isICalModalOpen, setIsICalModalOpen] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(['t_OK', 't_TBC', 't_Canceled', '2','3']);
  const [isEventInfoVisible, setIsEventInfoVisible] = useState(true);
  const windowWidth = useWindowResize();
  const [steps] = useState([ //här sätts stegen som visas i guiden
    {
      target: '#filter',
      // content: 'Här kan du filtrera vilka events som ska visas',
      content:getLabelText('app_hint_filter'),
      disableBeacon: true,
    },
    {
      target: '.ical-button',
      // content: 'Här kan du spara events till din iCal kalender',
      content:getLabelText('app_hint_ical'),
    },
    {
      target: '.event-li',
      // content: 'Här kan du se info om ett event',
      content:getLabelText('app_hint_eventinfo'),
    },
  ]);
  const [joyride, startTour, stopTour] = useJoyride(steps);

  // useEffect(() => {
  //   checkToken();
  // }, []);

  // funtion som hämtar events
  function useFetchEvents(selectedArtist) {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    const artistId = localStorage.getItem("selectedArtistId");
    async function fetchEvents() {
      try {
        const oneDay = 24 * 60 * 60 * 1000
        
        const response = await fetch(`${apiUrl}/Calendar?token=${token}&id=${artistId}`);
        const statusCode = response.status
        console.log(statusCode)

        if (statusCode === 403) {
          handleLogout()
        }

        const data = await response.json();
        Date.prototype.addDays = function(days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
      }
      function getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        let offset = startDate.getTimezoneOffset()
        currentDate = new Date(currentDate.getTime() - (offset*60*1000))
        while (currentDate < stopDate) {
          currentDate = currentDate.addDays(1);
          
        
          dateArray.push(new Date (currentDate).toISOString());
        }
        return dateArray;
      }
        let allevents = data.CalenderEvents
        allevents.forEach(ev => {
          let start = new Date(ev.Start)
          let end = new Date(ev.End)
          getDates(start,end).forEach(newdate=>{
            let addev = JSON.parse(JSON.stringify(ev))
            addev.Start = newdate
            addev.End = newdate
            data.CalenderEvents.push(addev)
          })
          
        });
        const sortedEvents = data.CalenderEvents
          .filter(event => new Date(event.Start) >= new Date().setHours(0, 0, 0, 0) - oneDay) // filtrerar bort events före igår
          .sort((a, b) => new Date(a.Start) - new Date(b.Start)); // sorterar events efter datum
        
        
        
        // console.log(sortedEvents)

        if (selectedArtist) {
          window.history.pushState({}, "", "events" + `/${selectedArtist.id}`);
        }
        
        
        setLoading(false);
        return sortedEvents
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };
    useEffect(async () => {
     
      setSelectedEvent(null)
      window.scrollTo(0, 0)
      
      setEvents(await fetchEvents());
      
    }, [artistId]);
    return { loading, events };
  };

  const { loading, events } = useFetchEvents(selectedArtist);

  // Renderar eventinfo komponenten på desktop
  function renderSelectedComponent() {
    if (windowWidth >= desktopBreakpoint && selectedEvent !== null && isEventInfoVisible) {
      return <EventInfo eventId={selectedEvent.ID} hideEventInfo={handleHideEventInfo} />;
    }
    return null
  };

  // funktion som döljer eventInfo komponenten
  function handleHideEventInfo() {
    setIsEventInfoVisible(false);
  };

  // funktion för när man väljer ett event
  const handleEventClick = useCallback((event) => {
    setIsEventInfoVisible(true);

    setSelectedEvent(prevEvent => {
      if (prevEvent && prevEvent.ID === event.ID) { // stänger ett event om man klickar på det igen
        window.history.pushState({}, '', 'events' + `/${selectedArtist.id}`);
        console.log("old")
        return null;
      } else {
        console.log("new")
       

        if (windowWidth < desktopBreakpoint) {
          history.push(`/event/${event.ID}`);
        }else{
          window.history.pushState({}, '', 'event' + `/${event.ID}`);
        }
        return event;
      }
    });
  }, [windowWidth, history, setSelectedEvent, selectedArtist]);


  // funktion för när man klickar på filterikonen
  function handleFilterIconClick() {
    setIsFilterOpen(!isFilterOpen);
    if (isICalModalOpen) {
      setIsICalModalOpen(!isICalModalOpen);
    }
  };

  // funktion som hanterar filter
  function handleStatusFilterChange(event) {
    const { id, checked } = event.target;
    setSelectedStatusFilter((prev) =>
      checked ? [...prev, id] : prev.filter((value) => value !== id)
    );
  };

  // komponent för filter
  const FilterSwitch = () => {
    return (
      <div style={{ display: isFilterOpen ? "block" : "none" }}>
        <div className={`${isFilterOpen ? "filter-modal" : "hidden"}`}>
          <h2>{getLabelText('app_eventlist_FilterEvents')}</h2>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="t_OK"
              onChange={handleStatusFilterChange}
              checked={selectedStatusFilter.includes('t_OK')}
            />
            <label className="form-check-label" htmlFor="t_OK">{getLabelText('app_eventlist_Confirmed')}</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="t_TBC"
              onChange={handleStatusFilterChange}
              checked={selectedStatusFilter.includes('t_TBC')}
            />
            <label className="form-check-label" htmlFor="t_TBC">{getLabelText('app_eventlist_TBC')}</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="t_Canceled"
              onChange={handleStatusFilterChange}
              checked={selectedStatusFilter.includes('t_Canceled')}
            />
            <label className="form-check-label" htmlFor="t_Canceled">{getLabelText('app_eventlist_Canceled')}</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="3"
              onChange={handleStatusFilterChange}
              checked={selectedStatusFilter.includes('3')}
            />
            <label className="form-check-label" htmlFor="3">{getLabelText('app_eventlist_Plan')}</label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="2"
              onChange={handleStatusFilterChange}
              checked={selectedStatusFilter.includes('2')}
            />
            <label className="form-check-label" htmlFor="2">{getLabelText('app_eventlist_Block')}</label>
          </div>
        </div>
      </div>
    );
  };

  // komponent som visar filtrerade eventen
  const DisplayEvents = ({ events, selectedStatusFilter }) => {
    const filteredEvents = events.filter((event) =>
      selectedStatusFilter.length > 0 ? selectedStatusFilter.includes(event.Status)|| selectedStatusFilter.includes(event.StatusType): true
    );

    return (
      <>
        {filteredEvents.map((event, index) => (
          <div key={index} onClick={() => handleEventClick(event)}>
            <li className={`event-li${selectedEvent && event.ID === selectedEvent.ID ? "-selected" : ""}`}>
              <div className="event-info">
                <div className={event.Status === "t_TBC" || event.Status === "t_Canceled" ? 'event-date-tbc' : "event-date"}>
                  <p>{formatEventDate(event.Start)}</p>
                </div>
                <div className={event.Status === "t_TBC" ? 'event-name-tbc' : event.Status === "t_Canceled" ? 'event-name-canceled' : "event-name"}>
                  <h3> {event.Place ? `${event.Status === "t_TBC" ? `TBC ${event.Place}` : `${event.Place}`}` : ''} </h3>
                  <h4>{event.Name}</h4>
                </div>
              </div>
              <div className={event.Status === "t_TBC" ? 'event-name-tbc' : event.Status === "t_Canceled" ? 'event-name-canceled' : ""}>
                {arrowRightIcon}
              </div>
            </li>
          </div>
        ))}
      </>
    );
  };

  // eventinfon som visas i headern
  const HeaderTitle = () => {
    if (selectedEvent && windowWidth >= desktopBreakpoint) {
      return (
        <div className="header-title2">
          {selectedEvent &&
            <>
              <h3>{selectedEvent.Name}</h3>
              <h3>{formatHeaderDate(selectedEvent.Start)}</h3>
            </>
          }
        </div>
      )
    } else return null
  }

  // funktion för att öppna/stänga iCal modulen
  function handleICalIconClick() {
    setIsICalModalOpen(!isICalModalOpen);
    if (isFilterOpen) {
      setIsFilterOpen(!isFilterOpen);
    }
  };

  // komponent som renderar guiden
  const ShowJoyride = () => {
    if (joyride.props.run) {
      return (
        joyride
      )
    } else return null
  }

  return (
    <>
      <PageHeader />
      <HeaderTitle />
      <div className='desktop'>
        <div className="event-list">
          <div className="event-list-title">
            <h1>{getLabelText('app_menu_UpcomingEvents')}</h1>
            <ShowJoyride />
            <div className="event-list-title-buttons">
              <button onClick={()=>startTour()}>{questionIcon}</button>
              <button className={isFilterOpen ? "filter-button" : ""} id="filter" onClick={() => handleFilterIconClick()}>{filterIcon}</button>
              <button title="iCal-länkar" className="ical-button" onClick={() => handleICalIconClick(isICalModalOpen, setIsICalModalOpen)}> {shareIcon}</button>
            </div>
          </div>
          {loading ? (
            <>
              <Placeholder />
            </>
          ) : (!events || events.length === 0) ? (
            <div className="event-li">
              <p> {getLabelText('app_eventlist_NoEvents')}</p>
            </div>
          ) : (
            <div>

              <div className="filter">
                <FilterSwitch />
                <ICalModal isICalModalOpen={isICalModalOpen} />
              </div>
              <DisplayEvents events={events} selectedStatusFilter={selectedStatusFilter} />
            </div>
          )}
        </div>
        <div className='selected-component'>
          {renderSelectedComponent()}
        </div>
      </div>
    </>
  );
}