import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { EventContext } from '../contexts/Context';
import { getLabelText } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { checkIcon, closeIcon, penIcon, circleIcon, dragIcon, okIcon, binIcon, shareIcon, loadingIcon } from "../icons/icons";
import { EventInfoHeader } from "../navs/Headers";

// komponent som visar lista på gäster
export function Guestlist({ selectedEventId }) {
  const history = useHistory();
  const [selectedEvent, setSelectedEvent] = useContext(EventContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editRight, setEditRight] = useState(localStorage.getItem("editRight"));
  const [isLoading, setIsLoading] = useState(true);
  const [editingGuest, setEditingGuest] = useState(null);
  const [showForm, setShowForm] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [newGuest, setNewGuest] = useState(null)
  const [guests, setGuests] = useState([]);
  const [groupedPersons, setGroupedPersons] = useState({
    approved: [],
    notApproved: []
  })

  let eventId;
  if (selectedEventId) {
    eventId = selectedEventId
  } else {
    eventId = localStorage.getItem("selectedEventId")
  }

  // hämtar data om gäster
  async function fetchGuestList() {
    try {
      const response = await fetch(`${apiUrl}/guestlist?id=${eventId}`);
      const data = await response.json();
      console.log(data)
      setGuests(data.Guests)
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGuestList()
  }, []);

  useEffect(() => {
    const grouped = guests.reduce((groups, person) => {
      if (person.Status.toString() === "0") {
        groups.approved.push(person)
      } else if (person.Status.toString() === "1") {
        groups.notApproved.push(person)
      }
      return groups
    }, {
      approved: [],
      notApproved: []
    })

    setGroupedPersons(grouped)
  }, [selectedEvent, guests]);


  // funktion för att flytta gäster
  function onDragEnd(result) {
    const { source, destination } = result
    if (!destination) return;
    if (source.droppableId === destination.droppableID && source.index === destination.index) return;

    setGroupedPersons(prev => {
      const updated = { ...prev }
      const [movedPerson] = updated[source.droppableId].splice(source.index, 1)

      if (destination.droppableId === 'approved') {
        movedPerson.Status = '0'
      } else if (destination.droppableId === 'notApproved') {
        movedPerson.Status = '1'
      }

      updated[destination.droppableId].splice(destination.index, 0, movedPerson)
      console.log(updated)
      console.log(movedPerson)

      return updated
    })

    let updatedguest = groupedPersons.approved.concat(groupedPersons.notApproved).find(x => x.ID == result.draggableId)
    console.log(updatedguest)

    postData(updatedguest)
  }

  function toggleSelectedPerson(id) {
    setSelectedPersons(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(personId => personId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const Checkbox = ({ id, isSelected, onToggle }) => {
    if (showCheckbox) {
      return (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onToggle(id)}
        />
      )
    } else return null
  }

  function handleInputChange(event) {
    const { name, value } = event.target
    setNewGuest(prevGuest => ({ ...prevGuest, [name]: value }))
    if (editingGuest) {
      setEditingGuest(prevGuest => ({ ...prevGuest, [name]: value }))
    }
  }

  // funktion för att lägga till elr redigera gäst
  async function handleAddGuest() {

    const newGuestEntry = {
      Name: newGuest.Name,
      Phone: newGuest.Phone,
      Email: newGuest.Email,
      Extra: newGuest.Extra,
      ID: editingGuest ? editingGuest.ID : "",
      Status: editingGuest ? editingGuest.Status : 1,
    }

    console.log(newGuestEntry)

    setNewGuest({
      Name: "",
      Phone: "",
      Email: "",
      Extra: "",
      ID: "",
      Status: 1
    })

    if (!editingGuest) {
      postData(newGuestEntry).then((response) => {
        newGuestEntry.ID = response,
          setGuests(prevGuests => [...prevGuests, newGuestEntry])
      })
    } else {
      postData(newGuestEntry).then((response) => {
        newGuestEntry.ID = response

        const index = guests.findIndex(guest => guest.ID === editingGuest.ID)

        const newGuests = [...guests]
        newGuests[index] = newGuestEntry

        setGuests(newGuests)
      })
    }

    console.log(guests)
    setEditingGuest(null)
    setShowForm(false)

  }

  function handleDeleteSelectedPersons() {
    selectedPersons.forEach(s => {
      deleteData(s)
    })
    setGuests(prevGuests => prevGuests.filter(guest => !selectedPersons.includes(guest.ID)))
    setSelectedPersons([])
    console.log(guests)
  }

  async function postData(guest) {

    console.log(guest)

    try {
      const response = await fetch(`${apiUrl}/guestlist?token=${token}&ID=${eventId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          guest
        ),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not update list.');
      }
      else {
        console.log(data)
        console.log("Data succesfully updated");
      }
      return data
    } catch (error) {
      console.log('Error updating data', error)
    }
  }

  async function deleteData(guest) {

    console.log(guest)

    try {
      const response = await fetch(`${apiUrl}/guestlist?id=${guest}&token=${token}`, {
        method: 'DELETE'
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Could not delete guest.');
      }
      else {
        console.log("Data succesfully updated");
        console.log(guests)
        var remaining = guests
        remaining = remaining.filter(function(s) { 
          return s.ID !== guest
      });
        setGuests(remaining)
      }

    } catch (error) {
      console.log('Error updating data', error)
    }
  }

  async function handleSave() {
    setIsEditing(false);
  }

  function handleAddNew() {
    setNewGuest({
      Name: "",
      Phone: "",
      Email: "",
      Extra: "",
      ID: "",
      Status: 1
    })
    setEditingGuest(false)
    setShowForm(!showForm)
  }

  function handleEnterEdit() {
    setShowForm(false)
    setIsEditing(true)
  }

  function handleCancel() {
    setGuests(guests)
    setIsEditing(false);
    setShowCheckbox(false)
  }

  const RenderInfo = () => {

    if (isLoading) {
      return (
        <div className="guest-list">
          <h3>{getLabelText('app_guestlist_Loading')} {loadingIcon}</h3>
        </div>
      )
    } else if (guests) {
      return (
        <ListOfGuests />
      )
    } else return null
  }

  // komponent som visar lista på gäster
  const ListOfGuests = () => {
    const filteredGuests = guests.filter(guest => guest.Status === "0")

    return (
      <>
        <div className="guest-list">
          <ul className="guestinfo">
            <h3 className="guestlist-title">
              {/* <span>{okIcon} {getLabelText('app_guestlist_Approved')}</span> */}
            </h3>
            {filteredGuests.map(guest => (
              <li className="project-person" key={guest.ID}>
                <h4>
                  {guest.Name} {guest.Extra > 0 && `+ ${guest.Extra}`}
                </h4>
                <p>{getLabelText('t_usersEmail')}: <a href={`mailto:${guest.Email}`}> {guest.Email}</a></p>
                <p>{getLabelText('t_Phone1')}: <a href={`tel:${guest.Phone}`}> {guest.Phone}</a></p>
              </li>
            ))}
          </ul>
        </div >
      </>
    )
  };

  // komponenten där man kan redigera gäster
  const GuestlistEdit = () => {
    if (guests.length > 0) {
      return (
        <div className="personel-edit">
          <DragDropContext onDragEnd={onDragEnd}>
            {['approved', 'notApproved'].map(status => (
              <Droppable key={status} droppableId={status} direction="vertical">
                {(provided) => (
                  <div className="status-section" {...provided.droppableProps} ref={provided.innerRef}>
                    <h3>{status === 'approved' ? getLabelText('app_guestlist_Approved') : getLabelText('app_guestlist_NotApproved')}</h3>
                    {groupedPersons[status].map((person, i) => (
                      <Draggable key={person.ID ? person.ID.toString() : `person_${i}`} draggableId={person.ID ? person.ID.toString() : `person_${i}`} index={i}>
                        {(provided) => (
                          <div className="project-person-parent" ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                            <div className='project-person-edit'>
                              <div className={status}>
                                <h3 onClick={() => {
                                  setEditingGuest(person)
                                  setNewGuest(person)
                                  setShowForm(true)
                                }}>{circleIcon} {person.Name} {person.Extra > 0 && `+ ${person.Extra}`}</h3>
                              </div>
                              {dragIcon}
                            </div>
                            <button style={{backgroundColor:"transparent"}}  onClick={()=>{deleteData(person.ID)}}>{binIcon}</button> 
                           
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      );
    } else return (
      <div className="guest-list">
      </div>
    )
  };

  return (
    <>
      <EventInfoHeader />
      <div className='desktop-info'>
        <div className="list">
          <div className="list-title">
            {/* {editRight >=3 ? (
              <div>
                <button onClick={handleSave}>
                  {checkIcon}
                </button>
                <button onClick={handleCancel}>
                  {closeIcon}
                </button>
              </div>
            ) : (
              <div className="setlist-title-buttons">
                <button onClick={() => handleEnterEdit()}>{penIcon}</button>
              </div>
            )} */}
            <h1>{getLabelText('app_eventlist_Guestlist')}</h1>
            {editRight >=3 ? (
              <div>
                <div className="setlist-title-buttons">
                  {selectedPersons.length > 0 && <button onClick={handleDeleteSelectedPersons}>{okIcon}</button>}
                  {/* <button onClick={() => setShowCheckbox(!showCheckbox)}>{binIcon}</button> */}
                </div>
              </div>
            ) : (
              <div className="setlist-title-buttons">
                <button onClick={() => history.push(`/approvedguests/${eventId}`)}>{shareIcon}</button>
              </div>
            )}
          </div>
          {editRight >=3 ? (
            <>
              <GuestlistEdit />
              {showForm ? (
                <div className="personel-edit">
                  <h2>{editingGuest ? "Edit guest" : "Add guest"}</h2>
                  <input className="setlist-input"
                    type="text"
                    placeholder={getLabelText('t_Name')}
                    name="Name"
                    value={editingGuest ? editingGuest.Name : newGuest.Name}
                    onChange={handleInputChange}
                  />
                  <input className="setlist-input"
                    type="email"
                    placeholder={getLabelText('t_usersEmail')}
                    name="Email"
                    value={editingGuest ? editingGuest.Email : newGuest.Email}
                    onChange={handleInputChange}
                  />
                  <input className="setlist-input"
                    type="text"
                    placeholder={getLabelText('t_Phone1')}
                    name="Phone"
                    value={editingGuest ? editingGuest.Phone : newGuest.Phone}
                    onChange={handleInputChange}
                  />
                  <input className="setlist-input"
                    type="number"
                    placeholder="Extra"
                    name="Extra"
                    value={editingGuest ? editingGuest.Extra : newGuest.Extra}
                    onChange={handleInputChange}
                  />
                  <div className="duration-input">
                    <button className="confirm-button" onClick={() => handleAddGuest()}>{getLabelText('app_setlist_Confirm')}</button>
                    <button className="cancel-button" onClick={() => setShowForm(!showForm)}>X</button>
                  </div>
                </div>
              ) : (
                <div className="add-guest">
                  <button onClick={() => handleAddNew()}>{getLabelText('app_guestlist_AddGuest')}</button>
                </div>
              )}
            </>
          ) : (
            <RenderInfo />
          )}
        </div>
      </div>
    </>
  );

}