import React, { useState, useEffect } from 'react';
import { checkToken, getLabelText } from '../../functions/functions';
import { apiUrl, token } from '../../App';
import { Logout } from '../misc/Logout';
import { PageHeader } from '../navs/Headers';
import CopyToClipboard from 'react-copy-to-clipboard';
import { arrowUpIcon, arrowDownIcon, headIcon, globeIcon, themeIcon, timeIcon, shareIcon, unitsIcon, passwordIcon, orangeShareIcon, penIcon, checkIcon, copyIcon } from '../icons/icons';

// sida där man kan se / ändra sina inställningar
export function Settings(props) {
  const [info, setInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState([]);
  const [object, setObject] = useState("");
  const [objectUserId, setObjectUserId] = useState(localStorage.getItem("userId"));
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [showTheme, setShowTheme] = useState(false);
  const [theme, setTheme] = useState('light-mode');
  const [showDateTimeFormat, setShowDateTimeFormat] = useState(false);
  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  const [timeFormat, setTimeFormat] = useState("24");
  const [showICalLinks, setShowICalLinks] = useState(false);
  const [showLoggedInDevices, setShowLoggedInDevices] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  // funktion för att hämta data om användaren
  async function fetchObjectUserData() {
    try {
      setLoading(true)
      const response = await fetch(`${apiUrl}/Object?id=${objectUserId}&token=${token}`);
      const data = await response.json();
      console.log(data)
      setInfo(data)
      setEditedInfo(data.Fields);
      setObject(data.Obj)
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch object user data', error);
    }
  }

  // funktion för att visa pil beroende på om komponenten är öppen
  function getArrowIcon(showDetails) {
    return showDetails ? arrowUpIcon : arrowDownIcon;
  };

  // sättar värden i formulär
  const formItems = info ? info.Definitions : [];

  // funktion för att öppna formulär
  function handleEdit() {
    setIsEditing(true);
  };

  // funktion för att ändra input fält
  function handleInputChange(object, objectUserId, fieldName, value, event) {

    let newEditedInfo = [...editedInfo];

    let itemToEdit = (newEditedInfo.some(item => item.FieldName === fieldName) ? newEditedInfo.find(item => item.FieldName === fieldName) : false);
    if (itemToEdit != false) {

      itemToEdit.FieldValue = value;

    } else {
      itemToEdit = {
        FieldName: fieldName,
        FieldGroup: info.Definitions.find(x => x.Name == fieldName).Group,
        FieldValue: value
      }
    }
    if (newEditedInfo.some(x => x.FieldName === itemToEdit.FieldName)) {
      newEditedInfo.find(x => x.FieldName === itemToEdit.FieldName).FieldValue = itemToEdit.FieldValue
    } else {
      newEditedInfo.push(itemToEdit)
    }

    setEditedInfo(newEditedInfo);
  }

  // funktion för att spara ändringar i formulär och posta till api
  async function handleSave() {

    const sendObject = info
    sendObject.Fields = editedInfo

    setIsEditing(false);
    const response = await fetch(`${apiUrl}/objects?token=${token}`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sendObject)
    });
    const data = await response.json();
  };

  const formItemsByGroup = formItems?.reduce((acc, item) => {
    if (!acc[item.Group]) {
      acc[item.Group] = [];
    }
    acc[item.Group].push(item);
    return acc;
  }, {});

  // komponent där man ändrar språk
  const LanguageSetting = () => {

    useEffect(() => {
      const savedLanguage = localStorage.getItem('language');
      if (savedLanguage) {
        setLanguage(savedLanguage);
      }
    }, []);

    // funktion för att uppdatera språk
    async function updateLanguage(newLanguage) {

      const response = await fetch(`${apiUrl}/Setting?token=${token}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // "userID": "",
          "Property": "appLanguage",
          "Value": newLanguage
        }),
      });

      if (response.ok) {
        setLanguage(newLanguage);
        props.onLanguageChange(newLanguage);
        localStorage.setItem('language', newLanguage);
        console.log(newLanguage)
        setShowLanguage(!showLanguage)
      } else {
        console.log("Error updating the language");
      }
    }

    return (
      <>
        <div className={showLanguage ? "active-setting" : "setting"} onClick={() => setShowLanguage(!showLanguage)}>
          <h2>{globeIcon} {getLabelText('app_settings_Language')}</h2>{getArrowIcon(showLanguage)}
        </div>
        {showLanguage && (
          <div className="setting-details">
            <form>
              <div className="form-item-box">
                <input
                  type="radio"
                  name="language"
                  id="se"
                  value="sv-SE"
                  checked={language === "sv-SE"}
                  onChange={(e) => updateLanguage(e.target.value)}
                />
                <label htmlFor="se">Svenska</label>
              </div>
              <div className="form-item-box">
                <input
                  type="radio"
                  name="language"
                  id="en"
                  value="en-GB"
                  checked={language === "en-GB"}
                  onChange={(e) => updateLanguage(e.target.value)}
                />
                <label  htmlFor="en">English</label>
              </div>
              <div className="form-item-box">
                <input
                  type="radio"
                  name="language"
                  id="da"
                  value="da-DK"
                  checked={language === "da-DK"}
                  onChange={(e) => updateLanguage(e.target.value)}
                />
                <label  htmlFor="da">Dansk</label>
              </div>
              <div className="form-item-box">
                <input
                  type="radio"
                  name="language"
                  id="no"
                  value="nn-NO"
                  checked={language === "nn-NO"}
                  onChange={(e) => updateLanguage(e.target.value)}
                />
                <label  htmlFor="no">Norsk</label>
              </div>
              <div className="form-item-box">
                <input
                  type="radio"
                  name="language"
                  id="cs"
                  value="cs-CZ"
                  checked={language === "cs-CZ"}
                  onChange={(e) => updateLanguage(e.target.value)}
                />
                <label  htmlFor="no">Czech</label>
              </div>
            </form>
          </div>
        )}
      </>
    )
  }

  // komponent där man ändrar tema
  const ThemeSetting = () => {

    useEffect(() => {
      const savedTheme = localStorage.getItem('theme');
      console.log(savedTheme)
      if (savedTheme) {
        setTheme(savedTheme);
      }
    }, []);

    // funktion för att uppdatera språk
    function handleThemeChange(event) {
      const newTheme = event.target.value;
      setTheme(newTheme);
      props.onThemeChange(newTheme);
      localStorage.setItem('theme', newTheme);
    };

    return (
      <>
        <div className={showTheme ? "active-setting" : "setting"} onClick={() => setShowTheme(!showTheme)}>
          <h2>{themeIcon} {getLabelText('app_settings_Theme')}</h2>{getArrowIcon(showTheme)}
        </div>
        {showTheme && (
          <div className="setting-details">
            <form >
              <div className="form-item-box" >
                <input id="light" type="radio" name="theme" value="light-mode" checked={theme === 'light-mode'} onChange={handleThemeChange} />
                <label htmlFor="light" >{getLabelText('app_settings_light')}</label>
              </div>
              <div className="form-item-box" >
                <input id="dark" type="radio" name="theme" value="dark-mode" checked={theme === 'dark-mode'} onChange={handleThemeChange} />
                <label htmlFor="dark" >{getLabelText('app_settings_dark')}</label>
              </div>
            </form>
          </div>
        )}
      </>
    )
  }

  const DateTimeSetting = () => {

    useEffect(() => {
      const savedDateFormat = localStorage.getItem('DateFormat');
      const savedTimeFormat = localStorage.getItem('TimeFormat');

      console.log(savedDateFormat)
      console.log(savedTimeFormat)

      if (savedDateFormat) {
        setDateFormat(savedDateFormat);
      } else localStorage.setItem('DateFormat', dateFormat)

      if (savedTimeFormat) {
        setTimeFormat(savedTimeFormat);
      }
    }, []);

    // funktion för att uppdatera datumformat
    const updateDateFormat = async (newDateFormat) => {
      const response = await fetch(`${apiUrl}/Setting?token=${token}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // "userID": "",
          "Property": "appDateFormat",
          "Value": newDateFormat
        }),
      });

      if (response.ok) {
        setDateFormat(newDateFormat);
        props.onDateFormatChange(newDateFormat);
        localStorage.setItem('DateFormat', newDateFormat);
      } else {
        console.log("Error updating the date format");
      }
    }

    // funktion för att uppdatera tidformat
    const updateTimeFormat = async (newTimeFormat) => {
      const response = await fetch(`${apiUrl}/Setting?token=${token}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // "userID": "",
          "Property": "appTimeFormat",
          "Value": newTimeFormat
        }),
      });
      if (response.ok) {
        setTimeFormat(newTimeFormat);
        props.onTimeFormatChange(newTimeFormat);
        localStorage.setItem('TimeFormat', newTimeFormat);

      } else {
        console.log("Error updating the time format");
      }
    }

    return (
      <>
        <div className={showDateTimeFormat ? "active-setting" : "setting"} onClick={() => setShowDateTimeFormat(!showDateTimeFormat)}>
          <h2>{timeIcon} {getLabelText('app_settings_DateTimeFormat')}</h2>{getArrowIcon(showDateTimeFormat)}
        </div>
        {showDateTimeFormat && (
          <div className="setting-details">
            <form>
              <div className="form-item-box" >
                <input
                  type="radio"
                  id="YYYY-MM-DD"
                  name="dateFormat"
                  value="YYYY-MM-DD"
                  checked={dateFormat === "YYYY-MM-DD"}
                  onChange={(e) => updateDateFormat(e.target.value)} />
                <label htmlFor="YYYY-MM-DD">YYYY-MM-DD</label>
              </div>
              <div className="form-item-box" >
                <input
                  type="radio"
                  id="DD/MM/YYYY"
                  name="dateFormat"
                  value="DD/MM/YYYY"
                  checked={dateFormat === "DD/MM/YYYY"}
                  onChange={(e) => updateDateFormat(e.target.value)} />
                <label htmlFor="DD/MM/YYYY">DD/MM/YYYY</label>
              </div>
              <div className="form-item-box" >
                <input
                  type="radio"
                  id="MM/DD/YYYY"
                  name="dateFormat"
                  value="MM/DD/YYYY"
                  checked={dateFormat === "MM/DD/YYYY"}
                  onChange={(e) => updateDateFormat(e.target.value)} />
                <label htmlFor="MM/DD/YYYY">MM/DD/YYYY</label>
              </div>
            </form>
            <form>
              <div className="form-item-box" >
                <input
                  type="radio"
                  id="12"
                  name="timeFormat"
                  value="12"
                  checked={timeFormat === "12"}
                  onChange={(e) => updateTimeFormat(e.target.value)} />
                <label htmlFor="12">12H</label>
              </div>
              <div className="form-item-box" >
                <input
                  type="radio"
                  id="24"
                  name="timeFormat"
                  value="24"
                  checked={timeFormat === "24"}
                  onChange={(e) => updateTimeFormat(e.target.value)} />
                <label htmlFor="24">24H</label>
              </div>
            </form>
          </div>
        )}
      </>
    )
  }

  const ICalSetting = () => {
    return (
      <>
        <div className={showICalLinks ? "active-setting" : "setting"} onClick={() => setShowICalLinks(!showICalLinks)}>
          <h2>{orangeShareIcon} Delade iCal-länkar</h2>{getArrowIcon(showICalLinks)}
        </div>
        {showICalLinks && (
          <div className="setting-details">
            {/* Add details for the "Delade iCal-länkar" setting here */}
          </div>
        )}
      </>
    )
  }

  const DeviceSetting = () => {
    return (
      <>
        <div className={showLoggedInDevices ? "active-setting" : "setting"} onClick={() => setShowLoggedInDevices(!showLoggedInDevices)}>
          <h2>{unitsIcon} Inloggade enheter</h2>{getArrowIcon(showLoggedInDevices)}
        </div>
        {showLoggedInDevices && (
          <div className="setting-details">
            {/* Add details for the "Inloggade enheter" setting here */}
          </div>
        )}
      </>
    )
  }

  const PasswordSetting = () => {
    return (
      <>
        <div className={showChangePassword ? "active-setting" : "setting"} onClick={() => setShowChangePassword(!showChangePassword)}>
          <h2>{passwordIcon} Byt lösenord</h2>{getArrowIcon(showChangePassword)}
        </div>
        {showChangePassword && (
          <div className="setting-details">
            <form>
              <input className="settings-input" type="password" placeholder="Nuvarande Lösenord *" />
              <input className="settings-input" type="password" placeholder="Nytt Lösenord *" />
              <input className="settings-input" type="password" placeholder="Bekräfta Lösenord *" />
            </form>
          </div>
        )}
      </>
    )
  }

  // funktion för att visa/dölja anvädndar info
  function showUserInfo() {
    setShowPersonalInfo(!showPersonalInfo)
    if (!showPersonalInfo && !info) {
      fetchObjectUserData()
    }
  }

  return (
    <>
      <PageHeader />
      <div className='settings'>
        <div className='settings-top'>
          <h3>{getLabelText('app_settings_LoggedInAs')} {username}</h3>
        </div>
        <div className={showPersonalInfo ? "active-setting" : "setting"} onClick={() => {
          showUserInfo()
          { isEditing && setIsEditing(false) };
        }}>
          <h2>{headIcon} {getLabelText('app_settings_PersonalInfo')}</h2>{getArrowIcon(showPersonalInfo)}
        </div>
        {showPersonalInfo && (
          <form className="setting-details-info">
            {Object.keys(formItemsByGroup || {}).map((group) => (
              <div key={group}>
                <div>
                  {(formItemsByGroup[group] || []).map((item) => {
                    const fieldValue = info.Fields.find(i => i.FieldName === item.Name)?.FieldValue;
                    return (
                      (!isEditing && fieldValue) || isEditing ? (
                        <div className="form-settings-item" key={item.Name}>
                          {item.Readonly !== 1 ? (
                            item.Group === "t_Default-ADDRESS" ? (
                              <h3>{getLabelText(`${item.Name.replace('t_Default-', '')}`)}</h3>
                            ) : (
                              <h3>{getLabelText(`${item.Name}`)}</h3>
                            )
                          ) : null}
                          {!isEditing ? (
                            item.Group === "EMAIL" ? (
                              <CopyToClipboard text={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}>
                                <div title="kopiera text" className="personinfo-item">
                                  <h4><a href={`mailto:${fieldValue}`}>{fieldValue}</a></h4>
                                  {copyIcon}
                                </div>
                              </CopyToClipboard>
                            ) : item.Group === "PHONE" ? (
                              <div title="kopiera text" className="personinfo-item">
                                <h4><a href={`tel:${fieldValue}`}>{fieldValue}</a></h4>
                                {copyIcon}
                              </div>
                            ) : (
                              <CopyToClipboard text={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}>
                                <div title="kopiera text" className="personinfo-formitem">
                                  <h4>{fieldValue}</h4>
                                </div>
                              </CopyToClipboard>
                            )
                          ) : (
                            item.Type === "radio" ? (
                              item.Options.split(", ").map((option) => (
                                <div className="form-item-box" key={option} >
                                  <input
                                    type="radio"
                                    id={item.Name}
                                    name={item.Name}
                                    value={option}
                                    checked={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue === option}
                                    onChange={(event) => handleInputChange('Person', objectUserId, item.Name, event.target.value, event)}
                                  />
                                  <label htmlFor={item.Name}>{option}</label>
                                </div>
                              ))
                            ) : item.Type === "checkbox" ? (
                              item.Options.split(", ").map((option) => (
                                <div className="form-item-box" key={option}>
                                  <input
                                    type="checkbox"
                                    id={item.Name}
                                    name={item.Name}
                                    value={option}
                                    checked={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue === option}
                                    onChange={(event) => handleInputChange('Person', objectUserId, item.Name, event.target.value, event)}
                                  />
                                  <label htmlFor={item.Name}>{option}</label>
                                </div>
                              ))
                            ) : item.Type === "textarea" ? (
                              <div className="personinfo-formitem">
                                <textarea className="form-settings-input"
                                  name={item.Name}
                                  value={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}
                                  onChange={(event) => handleInputChange('Person', objectUserId, item.Name, event.target.value, event)}
                                />
                                <CopyToClipboard text={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}>
                                  {copyIcon}
                                </CopyToClipboard>
                              </div>
                            ) : item.Group === "PHONE" ? (
                              <div className="personinfo-formitem">
                                <input
                                  className="form-settings-input"
                                  type="tel"
                                  name={item.Name}
                                  maxLength={item.MaxLength}
                                  value={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}
                                  onChange={(event) => handleInputChange('Person', objectUserId, item.Name, event.target.value, event)}
                                />
                                <CopyToClipboard text={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}>
                                  {copyIcon}
                                </CopyToClipboard>
                              </div>
                            ) : item.Readonly === 1 ? (
                              null
                            ) : (
                              <div className="personinfo-formitem">
                                <input className="form-settings-input"
                                  type={item.Type}
                                  id={item.Name}
                                  name={item.Name}
                                  value={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}
                                  onChange={(event) => handleInputChange('Person', objectUserId, item.Name, event.target.value, event)}
                                />
                                <CopyToClipboard text={editedInfo.find(i => i.FieldName === item.Name)?.FieldValue}>
                                  {copyIcon}
                                </CopyToClipboard>
                              </div>
                            )
                          )}
                        </div>
                      ) : null
                    );
                  })}
                </div>
              </div>
            ))}
            {!isEditing && <p onClick={handleEdit}>{penIcon}</p>}
            {isEditing && <p onClick={handleSave}>{checkIcon}</p>}
          </form>
        )}

        <LanguageSetting />
        <ThemeSetting />
        <DateTimeSetting />
        {/* <ICalSetting />
        <DeviceSetting />
        <PasswordSetting /> */}
        <Logout />
      </div>
    </>
  )
}