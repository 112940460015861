import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { EventContext } from '../contexts/Context';
import { SmallPlaceholder, checkToken, getLabelText, useWindowResize, handleFold, handleClose, getArrowIcon, getAttach } from '../../functions/functions';
import { apiUrl, token, desktopBreakpoint } from '../../App';
import { EventInfoHeader } from "../navs/Headers";
import { EventInfoblad } from './EventInfoblad';
import { DayScheme } from "./DayScheme";
import { PersonelInfo } from './PersonelInfo';
import { EventEdit } from './EventEdit';
import { foldIcon, expandIcon, closeIcon, arrowRightIcon, infoIcon, dayschemeIcon, venueIcon, staffIcon, guestIcon, setlistIcon, penIcon, dragIcon,pdficon } from '../icons/icons'
import { EventDetails } from './EventDetails';
import { Setlist } from './Setlist';
import { Guestlist } from './Guestlist';

// Komponent där man väljer info som ska visas om ett event
export function EventInfo() {
  const location = useLocation();
  const [selectedEvent, setSelectedEvent] = useContext(EventContext);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [eventPlace, setEventPlace] = useState(null);
  const [attachments, setAttachments] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const windowWidth = useWindowResize();
  const [isListOpened, setIsListOpened] = useState(true);
  const [venueNotes, setVenueNotes] = useState(null);
  const [generalNotes, setGeneralNotes] = useState(null);
  const [travelNotes, setTravelNotes] = useState(null);
  const [hotelNotes, setHotelNotes] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editRight, setEditRight] = useState(null);
  const [dayEvents, setDayEvents] = useState([]);
  const [personelData, setPersonelData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [setLists, setSetLists] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [fetchedVenueData, setFetchedVenueData] = useState([]);
  const [venueInfoItems, setVenueInfoItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkToken();
  }, []);

  let eventId;

  if (selectedEvent) {
    eventId = selectedEvent.ID
    localStorage.setItem("selectedEventId",eventId)
  } else {
    eventId = localStorage.getItem("selectedEventId")
  }

  // funktion för att hämta info om ett event (endast om man är inne på rätt artist)
  async function fetchEvents() {
    try {
      const artistId = localStorage.getItem("selectedArtistId");
      const response = await fetch(`${apiUrl}/Calendar?token=${token}&id=${artistId}`);
      const data = await response.json();
      const sortedEvents = data.CalenderEvents
     

      const eventId = localStorage.getItem("selectedEventId")
      const selectedEvent = sortedEvents.find(event => event.ID === eventId)
      if (selectedEvent) {
        setSelectedEvent(selectedEvent)
        setLoading(false);
      } else {
        // alert("Åtkomst saknas för detta event")
        window.location.href = ("/events")
      }

    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  }

  // funktion för att hämta dagschemat
  async function fetchDayschemeData() {
    try {
      const response = await fetch(`${apiUrl}/schedule?token=${token}&id=${eventId}`);
      const data = await response.json();
      setDayEvents(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchEventData() {
    
    try {
      const response = await fetch(`${apiUrl}/Event?id=${eventId}&token=${token}`);
      const data = await response.json();
      
      
      localStorage.setItem("eventName", data.ProductionName + " " + data.Subject)
      setProjectID(data.ProjectID)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // funktion som hämtar data om personal från API, repsons2 lägg till proid
  async function fetchPersonelData() {
    fetchEventData()
   
    try {
      const response = await fetch(`${apiUrl}/EventPerson?id=${eventId}&token=${token}`);
      const response2 = await fetch(`${apiUrl}/UserProjects?token=${token}&id=${eventId}&type=&start=${eventDate}&end=${eventEndDate}&all=false&proid=${localStorage.getItem("selectedArtistId")}`);
      const data = await response.json();
      const data2 = await response2.json();
      
      setPersonelData(data);
      setProjectData(data2);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchGuestList() {
    try {
      const response = await fetch(`${apiUrl}/guestlist?id=${eventId}`);
      const data = await response.json();
      
      setGuestData(data.Guests)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function fetchSetlists() {
    try {
      const response = await fetch(`${apiUrl}/setlist?id=${eventId}&token=${token}`);
      const data = await response.json();
      setSetLists(data)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // funktion som kollar om det finns data om venue
  async function fetchVenueData() {
    try {
      const eventResponse = await fetch(`${apiUrl}/Event?id=${eventId}&token=${token}`);
      const eventData = await eventResponse.json();

      const venueID = eventData.Show.VenueID;

      if (venueID) {
        const venueResponse = await fetch(`${apiUrl}/Object?id=${venueID}&token=${token}`);
        const venueData = await venueResponse.json();
        setFetchedVenueData(venueData);
        setVenueInfoItems(venueData.Fields)
      } else {
        setFetchedVenueData(null);
        setVenueInfoItems(null)
      }

      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // funktion för att kolla om man har rättigheter att redigera ett event
  async function fetchRights() {
    try {
      const artistId = localStorage.getItem("selectedArtistId");
      const response = await fetch(`${apiUrl}/UserProductionRights?id=${artistId}&date=${selectedEvent.Start}&token=${token}`);
      const data = await response.json();
      setEditRight(data)
      localStorage.setItem("editRight", data)
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch edit rights', error);
    }
  };

  // sätter rätt värden när man valt ett event
  // om man går in direkt på ett event vi URLen hämtas info på nytt
  useEffect(() => {
   
    setSelectedComponent(null)
    setShowForm(false)
    setAttachments(false)
    if (selectedEvent) {
      setSelectedEventId(selectedEvent.ID);
      setEventPlace(selectedEvent.Place);
      setEventDate(selectedEvent.Start)
      setEventEndDate(selectedEvent.End)
      localStorage.setItem("eventDate", selectedEvent.Start)
      setVenueNotes(selectedEvent.venueNotes)
      setGeneralNotes(selectedEvent.GeneralNotes)
      setTravelNotes(selectedEvent.TravelNotes)
      setHotelNotes(selectedEvent.HotelNotes)
      setStatusType(selectedEvent.StatusType)
      fetchRights()
      fetchDayschemeData()
      fetchPersonelData()
      fetchGuestList()
      fetchSetlists()
      fetchVenueData()
      fetchAttachments()
    } else {
      fetchEvents()
      fetchDayschemeData()
      fetchPersonelData()
      fetchGuestList()
      fetchSetlists()
      fetchVenueData()
    };

  }, [location, selectedEvent]);

  // funktion som hanterar vilken komponent som ska öppnas
  function handleNavLinkClick(component) {

    if (component === selectedComponent) {
      window.history.pushState({}, "", `/event/${selectedEventId}`);
      setSelectedComponent(null);
    } else {
      setSelectedComponent(component);
      window.history.pushState({}, "", `/${component}/${selectedEventId}`);
    }

    if (!showForm && component === 'event-edit' && statusType != "1") {
      setShowForm(true)
      window.history.pushState({}, "", `/${component}/${selectedEventId}`);
    } else if (!showForm && component === 'event' && statusType != "1") {
      setShowForm(true)
      window.history.pushState({}, "", `/${component}/${selectedEventId}`);
    }
  };

  // visar vald komponent
  const RenderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'infoblad':
        return <EventInfoblad />;
      case 'dayscheme':
        return <DayScheme selectedEventId={selectedEventId} dayEvents={dayEvents} eventDate={eventDate} />;
      case 'venue':
        return <EventDetails selectedEventID={selectedEventId} selectedComponent={selectedComponent} venueData={fetchedVenueData} venueInfo={venueInfoItems} />;
      case 'personel':
        return <PersonelInfo personelData={personelData} projectData={projectData} selectedEventId={selectedEventId} />;
      case 'guestlist':
        return <Guestlist selectedEventId={selectedEventId} />;
      case 'setlist':
        return <Setlist projectData={projectData} projectID={projectID} selectedEventId={selectedEventId} />;
      case 'event-edit':
        if (showForm) return <EventEdit setShowForm={setShowForm} />;
      default:
        return null;
    }

  };

  // funktion för att stänga ner komponenten
  function handleClose() {
    setSelectedEvent(null);
  };

  const PenIcon = () => {
    if (statusType !== "1" && (editRight === 2 || editRight === 3 || editRight === 4)) {
      return (
        <button onClick={() => handleNavLinkClick('event-edit')}>{penIcon}</button>
      )
    } else {
      return null
    }
  }

  const ListTitle = () => {
    // console.log(editRight)
    return (
      <div className='list-title'>
        <button className="hide-button" onClick={() => handleFold(isListOpened, setIsListOpened)}>{getArrowIcon(isListOpened, foldIcon, expandIcon)}</button>
        <h1>{`${isListOpened ? 'Event' : ''}`}</h1>
        <div className="event-title-icons">
          <PenIcon />
          <button className="hide-button" onClick={handleClose}>{closeIcon}</button>
        </div>
      </div>
    )
  }
   function fetchAttachments() {
    var test = getAttach(selectedEvent.ID, 'ustage-uploads')
    console.log("TEST")
   
      console.log(selectedEvent)
    var list = ["general", "travel", "hotel"]
    list.forEach(x=>{
     
        const att = getAttach(`${window.localStorage.getItem('owner')}/event/${selectedEvent.Start.substring(0,4)}/${selectedEvent.ID}/${x}`, 'ustage-upload')
        att.then((a) => {
          if(a.length >0)
            setAttachments(true)
        })
        
        const att2 = getAttach(`${selectedEvent.ID}/${x}`, 'ustage-uploads')
        att2.then((a) => {
          if(a.length >0)
           setAttachments(true)
        })
    
    })
  
};

  async function downloadPDF() {
    try {
      fetch(`${apiUrl}/pdf?id=${eventId}&token=${token}`).then((response) => {
        response.blob().then((blob) => {
       
          if(blob.size>100){

            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "contract.pdf";  
            alink.click();
          }else{
            alert("No PDF")
          }
            
        });
    });
      
      
     
    } catch (error) {
      console.error('Failed to fetch pdf', error);
    }
  };
  const PDFbtn = () => {
    
    if(editRight == 4 && selectedEvent.Status == "t_OK"){
      return (
      <div className="nav-link" onClick={() => downloadPDF()}>
      <li className={'eventinfo'}><h2>{pdficon} Download PDF</h2></li>
    </div> )
    }else{
      return (<></>)
    }
  }

  const InfoSelector = () => {
    if (loading) {
      return (
        <>
          <div className={`${isListOpened ? 'eventinfo-list' : 'small-list'}`}>
            <ListTitle />
            <SmallPlaceholder />
          </div>
        </>
      )
    } else return (
      <div className={`${isListOpened ? 'eventinfo-list' : 'small-list'}`}>
        <ListTitle />
        <div className="nav-link" onClick={() => handleNavLinkClick('infoblad')} >
          <li className={selectedComponent === 'infoblad' ? 'eventinfo-selected' : generalNotes || hotelNotes || travelNotes || attachments   ? 'eventinfo' : 'empty-eventinfo'}><h2>{infoIcon} {getLabelText('app_eventinfo_Itinerary')}</h2>{arrowRightIcon}</li>
        </div>
        <div className="nav-link" onClick={() => handleNavLinkClick('dayscheme')}>
          <li className={selectedComponent === 'dayscheme' ? 'eventinfo-selected' : dayEvents.length > 0 ? 'eventinfo' : 'empty-eventinfo'}><h2>{dayschemeIcon} {getLabelText('app_eventinfo_Schedule')}</h2>{arrowRightIcon}</li>
        </div>
        <div className="nav-link" onClick={() => handleNavLinkClick('venue')}>
          <li className={selectedComponent === 'venue' ? 'eventinfo-selected' : venueNotes || fetchedVenueData ? 'eventinfo' : 'empty-eventinfo'}><h2>{venueIcon} {getLabelText('app_eventinfo_Venue')}</h2>{arrowRightIcon}
          </li>
        </div>
        <div className="nav-link" onClick={() => handleNavLinkClick('personel')}>
          <li className={selectedComponent === 'personel' ? 'eventinfo-selected' : personelData.length > 0 ? 'eventinfo' : 'empty-eventinfo'}><h2>{staffIcon} {getLabelText('app_eventinfo_Personnel')}</h2>{arrowRightIcon}</li>
        </div>
        <div className="nav-link" onClick={() => handleNavLinkClick('guestlist')}>
          <li className={selectedComponent === 'guestlist' ? 'eventinfo-selected' : guestData.length > 0 ? 'eventinfo' : 'empty-eventinfo'}><h2>{guestIcon} {getLabelText('app_eventlist_Guestlist')}</h2>{arrowRightIcon}</li>
        </div>
        <div className="nav-link" onClick={() => handleNavLinkClick('setlist')}>
          <li className={selectedComponent === 'setlist' ? 'eventinfo-selected' : setLists.length > 0 ? 'eventinfo' : 'empty-eventinfo'}><h2>{setlistIcon} Setlist </h2>{arrowRightIcon}</li>
        </div>
       
        <PDFbtn/>
        
        
      </div>
    ) 
  } 

  // komponenter visas på olika sätt på desktop och mobil
  if (windowWidth > desktopBreakpoint) {
  
    return (
      <>
        <EventInfoHeader />
        <div className='desktop-info'>
          <InfoSelector />
          <div className='selected-component'>
            <RenderSelectedComponent />
          </div>
        </div>
      </>
    )
  } else if (windowWidth < desktopBreakpoint && selectedComponent) {
    return (
      <>
        <EventInfoHeader />
        <div className='desktop-info'>
          <RenderSelectedComponent />
        </div>
      </>
    )
  } else if (windowWidth < desktopBreakpoint) {
    return (
      <>
        <EventInfoHeader />
        <InfoSelector />
      </>
    )
  }
}