import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLabelText } from '../../functions/functions';
import { desktopBreakpoint } from '../../App';
import { Navbar, NavLink } from 'reactstrap';
import { Logout } from '../misc/Logout';
import logo from '../icons/USlogo.png';
import { menuIcon } from '../icons/icons'

const activeColor = "#FF5500"
const notActiveColor = "#b3b3b3"

// navigeringsmenyn
export function NavMenu() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > desktopBreakpoint);
  const [linkText, setLinkText] = useState({
    home: '',
    event: '',
    calendar: '',
    tour: '',
    settings: ''
  });

  const eventId = localStorage.getItem("eventId");

  // sätter text i menyn beroende på om menyn är öppnad eller ej
  useEffect(() => {
    setActiveLink(location.pathname);
    if (isExpanded) {
      setLinkText({
        event: `${getLabelText('app_menu_UpcomingEvents')}`,
        calendar: `${getLabelText('app_menu_Calendar')}`,
        tour: `${getLabelText('app_menu_Tourinfo')}`,
        settings: `${getLabelText('app_menu_Settings')}`
      });
    } else {
      setLinkText({
        event: '',
        calendar: '',
        tour: '',
        settings: ''
      });
    }
  }, [location, isExpanded]);

  // länkar till olika sidor där ikonen ska vara orange om man är inne på den sidan

  const HomeNavLink = () => {
    return (
      <NavLink tag={Link} className={activeLink === "/" ? "activeColor" : ""} to="/">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill={activeLink === "/" ? activeColor : notActiveColor} d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8h5Z" />
        </svg>
        <h2>{linkText.home}</h2>
      </NavLink>
    )
  }

  const EventsNavLink = () => {
    return (
      <NavLink tag={Link} className={activeLink === "/event" ? "activeColor" : ""} to={`/events`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path fill={activeLink.includes('/event') || activeLink === `/event/${eventId}` || activeLink === `/infoblad/${eventId}` || activeLink === `/eventdetails/${eventId}` ? activeColor : notActiveColor} d="M1.228 10.891a.528.528 0 0 0-.159.69l1.296 2.244c.133.23.438.325.677.208L7 12.116V19h2v-7.854l4.071-1.973l-2.62-4.54l-9.223 6.258zm17.229-7.854a4.061 4.061 0 0 0-5.546-1.484c-.91.525-1.508 1.359-1.801 2.289l2.976 5.156c.951.212 1.973.11 2.885-.415a4.06 4.06 0 0 0 1.486-5.546z" />
        </svg>
        <h2>{linkText.event}</h2>
      </NavLink>
    )
  }

  const CalenderNavLink = () => {
    return (
      <NavLink tag={Link} className={activeLink === "/calendar" ? "activeColor" : ""} to={`/calendar`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill={activeLink === "/calendar" ? activeColor : notActiveColor} d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
        </svg>
        <h2>{linkText.calendar}</h2>
      </NavLink>
    )
  }

  const TourNavLink = () => {
    return (
      <NavLink tag={Link} className={activeLink === "/tour" ? "activeColor" : ""} to={`/tour`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 15 15"><path fill={activeLink === "/tour" ? activeColor : notActiveColor} d="M2 3c0-1.1.9-2 2-2h7c1.1 0 2 .9 2 2v8c0 1-1 1-1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1H5v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c-1 0-1-1-1-1V3Zm1.5 1c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5h8c.28 0 .5-.22.5-.5v-3c0-.28-.22-.5-.5-.5h-8ZM4 9c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1Zm7 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1ZM4 2.5c0 .28.22.5.5.5h6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-6c-.28 0-.5.22-.5.5Z" /></svg>
        <h2>{linkText.tour}</h2>
      </NavLink>
    )
  }

  const SettingsNavLink = () => {
    return (
      <NavLink tag={Link} className={activeLink === "/settings" ? "activeColor" : ""} to="/settings">
        <div className='link'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill={activeLink === "/settings" ? activeColor : notActiveColor} d="M13.875 22h-3.75q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.613-.3t-.562-.375l-2.175.9q-.35.125-.7.025t-.55-.425L2.4 15.4q-.2-.325-.125-.7t.375-.6l1.875-1.425Q4.5 12.5 4.5 12.337v-.674q0-.163.025-.338L2.65 9.9q-.3-.225-.375-.6t.125-.7l1.85-3.225q.175-.35.537-.438t.713.038l2.175.9q.275-.2.575-.375t.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3t.562.375l2.175-.9q.35-.125.7-.025t.55.425L21.6 8.6q.2.325.125.7t-.375.6l-1.875 1.425q.025.175.025.338v.674q0 .163-.05.338l1.875 1.425q.3.225.375.6t-.125.7l-1.85 3.2q-.2.325-.563.438t-.712-.013l-2.125-.9q-.275.2-.575.375t-.6.3l-.3 2.325q-.05.375-.325.625t-.65.25Zm-1.825-6.5q1.45 0 2.475-1.025T15.55 12q0-1.45-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12q0 1.45 1.012 2.475T12.05 15.5Z" />
          </svg>
          <h2>{linkText.settings}</h2>
        </div>
      </NavLink>
    )
  }

  // menyn som visas på desktop
  const LeftNavMenu = () => {
    return (
      <footer className={`footer${isDesktop && isExpanded ? '-expanded' : ''}`}>
        <Navbar>
          <div>
            <button className="menu-icon" onClick={() => setIsExpanded(!isExpanded)}>{menuIcon}
            </button>
          </div>
          <EventsNavLink />
          <CalenderNavLink />
          <TourNavLink />
          <SettingsNavLink />
          <div className={isExpanded ? "" : "hidden"}>
            <Logout />
            <img src={logo} alt="logo" />
            <h4><a href={`mailto:support@ustage.com`}>support@ustage.com</a>  </h4>
          </div>
        </Navbar>
      </footer>
    )
  }

  // menyn som visas i mobilen
  const Footer = () => {
    return (
      <footer className='footer'>
        <Navbar>
          <HomeNavLink />
          <EventsNavLink />
          <CalenderNavLink />
          <TourNavLink />
        </Navbar>
      </footer>
    )
  }

  const RenderNavMenu = () => {
    if (isDesktop) {
      return <LeftNavMenu />
    } else
      return (
        <Footer />
      )
  }

  return (
    <RenderNavMenu />
  );
};